export interface FormSchema {
  [key: string]: {
    value: string | number | null;
    error: string;
    classValue?: string;
  };
}

export interface ValidationSchema {
  [key: string]: {
    required: boolean;
    validator?: {
      regEx: RegExp;
      error: string;
    };
    isEqualTo?: string;
    hasToMatch?: {
      value: string;
      error: string;
    };
  };
}

export interface ITransactionType {
  id: number;
  label: string;
  case_impot: number;
  credit: boolean;
}

export interface IImpotFiche {
  case_impot: number;
  label: string;
  credit: boolean;
  total: string;
}

export interface ITransactionData {
  transaction_id: number;
  date: string;
  depense_recette: boolean;
  label: string;
  description: string;
  amount: string;
  created_on: string;
  updated_on: string;
  deleted_on: string;
}

export interface ITransactionDataForm {
  id?: number;
  label: string;
  fk_ref_transaction_type_id: number | null;
  date: string;
  amount: number;
}

export interface ICashflowDataForm {
  id?: number;
  label: string;
  credit: boolean;
  date: string;
  amount: number;
}

export interface ImpotsTransactionItemView {
  id: number;
  label: string;
  date: string;
  fk_ref_transaction_type_id: number;
  amount: number;
  transaction_type_label: string;
  credit: boolean;
  case_impot_label: string;
  case_impot: number;
}

export interface ImpotsTransactionTableData {
  data: ImpotsTransactionItemView[];
  limit: number;
  offset: number;
  totalCount: number;
}

export interface TransactionItemView {
  transaction_id: number;
  date: string;
  cashflow: boolean;
  label: string;
  description: string;
  amount: string;
  created_on: string;
  updated_on: string;
  deleted_on: string;
}

export interface ITransactionTableData {
  data: TransactionItemView[];
  limit: number;
  offset: number;
  totalCount: number;
}

export interface ITableConfig {
  limit: number;
  offset: number;
  orderBy: string;
  sort: string;
}

export const PERCENTAGE_FORMAT = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const PERCENTAGE_WITHOUT_DECIMAL_FORMAT = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const CURRENCY_EURO_FORMAT = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

export const CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const DATE_TIME_FORMAT = Intl.DateTimeFormat('fr-FR', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const DATE_MONTH_YEAR_FORMAT = Intl.DateTimeFormat('fr-FR', {
  month: 'long',
  year: 'numeric',
});
