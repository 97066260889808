import { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { IAccountCNG, IAccountCNGChartData, IAccountCNGDashboard, IAccountCNGStatusForm } from '../../Types';
import ApiProvider from '../../ApiProvider';
import { DEFAULT_TABLE_SIZE } from '.';

type Props = {
  account?: IAccountCNG;
  statusToAddEdit?: IAccountCNGStatusForm;
  offset: number;
  callbackFunction: ({
    accountData,
    globalDashboardData,
    globalChartData,
  }: {
    accountData: IAccountCNG;
    globalDashboardData: IAccountCNGDashboard;
    globalChartData: IAccountCNGChartData;
  }) => void;
  cancelCallbackFunction: () => void;
};

type State = {
  isProcessing: boolean;
  error: string | null;
  success: string | null;
  formData?: IAccountCNGStatusForm;
};

function AddEditStatusForm({ account, offset, statusToAddEdit, cancelCallbackFunction, callbackFunction }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isProcessing: false,
      error: null,
      success: null,
    }
  );

  useEffect(() => {
    if (statusToAddEdit) {
      setState({ formData: statusToAddEdit });
    }
  }, [statusToAddEdit]);

  const onCancelHandler = useCallback(() => {
    setState({
      formData: undefined,
      isProcessing: false,
      error: null,
      success: null,
    });
    cancelCallbackFunction();
  }, [cancelCallbackFunction]);

  const onFormInputHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
      let newValue = event.target.value;

      if (event.target.type === 'checkbox') {
        // @ts-ignore
        newValue = event.target.checked;
      }

      setState({
        formData: {
          ...state.formData,
          [event.target.name]: newValue,
        } as IAccountCNGStatusForm,
      });
    },
    [state.formData]
  );

  const handleFormSubmit = useCallback(
    async (accountStatus?: IAccountCNGStatusForm) => {
      if (accountStatus) {
        setState({ isProcessing: true });

        ApiProvider.addOrUpdateAccountStatus({
          status: accountStatus,
          offset: offset,
          limit: DEFAULT_TABLE_SIZE,
        })
          .then((response) => {
            setState({
              success: response.message,
            });
            callbackFunction(response.data);
          })
          .catch((err) => {
            setState({ error: err.message, isProcessing: false });
          })
          .finally(() => {
            setState({ isProcessing: false });
          });
      }
    },
    [offset, callbackFunction]
  );

  return (
    <Modal size="lg" backdrop="static" show={state.formData === undefined ? false : true} onHide={onCancelHandler}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(state.formData);
        }}
      >
        <Modal.Header closeButton={!state.isProcessing}>
          {state.formData && account && (
            <Modal.Title>
              {state.formData.account_cng_status_id === null
                ? `${account.name} - Ajouter une statut`
                : `${account.name} - Modifier une statut`}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.success && (
            <Alert className="mb-0" variant="success">
              {state.success}
            </Alert>
          )}
          {state.formData && !state.success && (
            <>
              <Form.Group className="mb-3" controlId="status_date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  disabled={state.isProcessing}
                  onChange={onFormInputHandler}
                  name="status_date"
                  defaultValue={state.formData.status_date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="current_value">
                <Form.Label>Situation</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="any"
                  disabled={state.isProcessing}
                  onChange={onFormInputHandler}
                  name="current_value"
                  value={state.formData.current_value}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="invested_amount">
                <Form.Label>Investissement</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="any"
                  disabled={state.isProcessing}
                  onChange={onFormInputHandler}
                  name="invested_amount"
                  value={state.formData.invested_amount}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={state.isProcessing}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Fermer
          </Button>
          {!state.success && (
            <Button
              variant="primary"
              type="submit"
              className="d-flex flex-row gap-2 align-items-center"
              disabled={state.isProcessing}
            >
              {state.isProcessing ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span>Enregistrement...</span>
                </>
              ) : (
                'Enregistrer'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditStatusForm;
