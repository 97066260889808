import packageJson from '../../package.json';

const Footer = () => {
  return (
    <footer className="footer d-flex flex-column justify-content-center align-items-center m-3">
      <h6>© di2pra.com 2023 - Mes Apps - v{packageJson.version}</h6>
    </footer>
  );
};

export default Footer;
