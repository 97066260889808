import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, Outlet, useLocation, useNavigation } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import { AuthProvider } from './providers/AuthProvider';
import Loading from './components/Loading';
import { useEffect } from 'react';
import { UpdateTheme } from './App';

export default function AppLayout() {
  const navigation = useNavigation();
  const location = useLocation();

  useEffect(() => {
    document.title = 'DI2PRA - Mes Apps';
  }, [location]);

  useEffect(() => {
    const updateTokenListener = (_: any) => {
      if (document.visibilityState === 'visible') {
        AuthProvider.updateAuthSession();
      }
    };

    const updateThemeListener = (_: any) => {
      UpdateTheme();
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateThemeListener);
    window.addEventListener('visibilitychange', updateTokenListener);

    return () => {
      window.removeEventListener('visibilitychange', updateTokenListener);
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateThemeListener);
    };
  }, []);

  if (navigation.state === 'loading') {
    return (
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <Container className="flex-grow-1">
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  } else {
    if (!AuthProvider.isUserSignedInAndValid()) {
      return <Navigate to="/login" />;
    } else {
      return (
        <div className="d-flex flex-column min-vh-100">
          <Header />
          <Container className="flex-grow-1">
            <Row>
              <Col>
                <Outlet />
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      );
    }
  }
}
