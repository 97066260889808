import { ChangeEvent, useCallback } from 'react';
import { Col, Form, Placeholder, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

type Props = {
  availableYears: number[];
  onFilterUpdate: ({ year }: { year?: string }) => void;
};

function Filter({ availableYears, onFilterUpdate }: Props) {
  const { year } = useParams();

  const onSelectedYearChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = String(event.target.value);
    onFilterUpdate({ year: selectedYear });
  }, []);

  return (
    <Row className="mb-3">
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Select aria-label="Choisir l'année" name="year" onChange={onSelectedYearChange} value={year}>
            {availableYears.map((item, key) => {
              return (
                <option key={key} value={item}>
                  {item}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row className="mb-3">
      <Col>
        <Placeholder xs={5} size="lg" />
      </Col>
    </Row>
  );
}

Filter.Placeholder = ComponentPlaceholder;

export default Filter;
