import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { useReducer } from 'react';
import GlobalSituation from './GlobalSituation';
import { IAccountCGGlobalChartData } from '../../../Types';
import GlobalYearOverYearPercentage from './GlobalYearOverYearPercentage';
import GlobalYearOverYearEuros from './GlobalYearOverYearEuros';

type Props = {
  globalChartData: IAccountCGGlobalChartData;
};

type State = {
  current: GRAPH_TYPE;
};

export enum GRAPH_TYPE {
  GLOBAL_SITUATION = 'GLOBAL_SITUATION',
  YEAR_OVER_YEAR_PERCENTAGE = 'YEAR_OVER_YEAR_PERCENTAGE',
  YEAR_OVER_YEAR_EUROS = 'YEAR_OVER_YEAR_EUROS',
}

function GlobalGraph({ globalChartData }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      current: GRAPH_TYPE.YEAR_OVER_YEAR_PERCENTAGE,
    }
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant={state.current === GRAPH_TYPE.YEAR_OVER_YEAR_PERCENTAGE ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.YEAR_OVER_YEAR_PERCENTAGE });
              }}
            >
              Annuelle (%)
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.YEAR_OVER_YEAR_EUROS ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.YEAR_OVER_YEAR_EUROS });
              }}
            >
              Annuelle (€)
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.GLOBAL_SITUATION ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.GLOBAL_SITUATION });
              }}
            >
              Global (€)
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {state.current === GRAPH_TYPE.GLOBAL_SITUATION && (
        <GlobalSituation globalSituationData={globalChartData.globalSituationData} />
      )}
      {state.current === GRAPH_TYPE.YEAR_OVER_YEAR_PERCENTAGE && (
        <GlobalYearOverYearPercentage globalYearOverYearData={globalChartData.globalYearOverYearData} />
      )}
      {state.current === GRAPH_TYPE.YEAR_OVER_YEAR_EUROS && (
        <GlobalYearOverYearEuros globalYearOverYearData={globalChartData.globalYearOverYearData} />
      )}
    </>
  );
}

function ComponentPlaceholder() {
  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example">
            <Button disabled variant="secondary">
              Annuelle (%)
            </Button>
            <Button disabled variant="secondary">
              Annuelle (€)
            </Button>
            <Button disabled variant="secondary">
              Global (€)
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mb-3"></Row>
    </>
  );
}

GlobalGraph.Placeholder = ComponentPlaceholder;

export default GlobalGraph;
