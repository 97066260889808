import { useCallback, useEffect, useReducer } from 'react';
import { Alert, Button, Col, Placeholder, Row } from 'react-bootstrap';
import Filter from './Filter';
import TransactionTable from './TransactionTable';
import ApiProvider from '../../ApiProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PinelFicheImpotData,
  PinelRefImpotFiche,
  PinelRefTransactionType,
  PinelTransactionForm,
  PinelTransactionItem,
} from '../../Types';
import FicheImpotTable from './FicheImpotTable';
import AddEditTransactionForm from './AddEditTransactionForm';

export const PAGE_SIZE: number = 50;

type State = {
  error: string | null;
  data?: {
    reference: {
      impotFiche: PinelRefImpotFiche[];
      transactionType: PinelRefTransactionType[];
    };
    availableYears: number[];
    ficheImpotData: PinelFicheImpotData[] | null;
    tableData: {
      rows: PinelTransactionItem[];
      totalCount: number;
    } | null;
  };
  transactionToAddEdit?: PinelTransactionForm;
};

function FicheImpot() {
  const { year, page } = useParams();
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    { error: null }
  );

  const navigate = useNavigate();
  let location = useLocation();
  const offset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  useEffect(() => {
    ApiProvider.getFicheImpot({ year: Number(year), limit: PAGE_SIZE, offset: offset })
      .then((data) => {
        setState({
          data: {
            ...state.data,
            ...data,
          },
        });
      })
      .catch((error: Error) => {
        setState({ error: error.message });
      });
  }, [location]);

  const onFilterUpdate = useCallback(
    async ({ year }: { year?: string }) => {
      setState({
        data: {
          ...state.data!,
          ficheImpotData: null,
          tableData: null,
        },
      });

      navigate(`/pinel/fiche-impot/${year}`);
    },
    [state.data]
  );

  const setTransactionToAddEdit = useCallback((newState: PinelTransactionForm) => {
    setState({
      transactionToAddEdit: newState,
    });
  }, []);

  const onPaginate = useCallback(
    async ({ year, nextPage }: { year?: string; nextPage: number }) => {
      setState({
        data: {
          ...state.data!,
          tableData: null,
        },
      });

      navigate(`/pinel/fiche-impot${year ? '/' + year : ''}${nextPage ? '/page/' + nextPage : ''}`);
    },
    [state.data]
  );

  const addOrEditCallbackHandler = useCallback(
    ({
      availableYears,
      ficheImpotData,
      tableData,
    }: {
      availableYears: number[];
      ficheImpotData: PinelFicheImpotData[];
      tableData: {
        rows: PinelTransactionItem[];
        totalCount: number;
      };
    }) => {
      setState({
        data: {
          ...state.data!,
          availableYears: availableYears,
          ficheImpotData: ficheImpotData,
          tableData: tableData,
        },
        transactionToAddEdit: undefined,
      });
    },
    [state.data]
  );

  const cancelCallbackFunction = useCallback(() => {
    setState({
      transactionToAddEdit: undefined,
    });
  }, []);

  if (state.error != null) {
    return (
      <Alert className="mb-0" variant="danger">
        {state.error}
      </Alert>
    );
  }

  if (state.data === undefined) {
    return (
      <>
        <Row className="mb-3">
          <Col>
            <Placeholder.Button variant="primary" xs={2} size="lg" />
          </Col>
        </Row>
        <Filter.Placeholder />
        <TransactionTable.Placeholder />
        <FicheImpotTable.Placeholder />
      </>
    );
  }

  return (
    <>
      <AddEditTransactionForm
        offset={offset}
        year={year}
        transactionType={state.data.reference.transactionType}
        transactionToAddEdit={state.transactionToAddEdit}
        callbackFunction={addOrEditCallbackHandler}
        cancelCallbackFunction={cancelCallbackFunction}
      />
      <Row className="mb-3">
        <Col>
          <Button
            onClick={() => {
              setTransactionToAddEdit({
                pinel_impot_transaction_id: null,
                label: '',
                date: new Date().toISOString().substring(0, 10),
                fk_ref_transaction_type_id: null,
                amount: 0,
              });
            }}
            variant="primary"
          >
            Ajouter une transaction
          </Button>
        </Col>
      </Row>
      <Filter availableYears={state.data.availableYears} onFilterUpdate={onFilterUpdate} />
      <TransactionTable
        tableData={state.data.tableData}
        onPaginate={onPaginate}
        setTransactionToAddEdit={setTransactionToAddEdit}
      />
      <FicheImpotTable ficheImpotData={state.data.ficheImpotData} />
    </>
  );
}
export default FicheImpot;
