import { FetchProvider } from '../../providers/FetchProvider';
import { IAccountingDashboardData, IAccountingData, IAccountingTableData } from './Types';

const getAccounting = async ({
  month,
  year,
  limit,
  offset,
  orderBy,
  sort,
  tableOnly,
}: {
  month?: string;
  year?: string;
  limit: number;
  offset: number;
  orderBy: string;
  sort: string;
  tableOnly?: boolean;
}): Promise<IAccountingData> => {
  const params = new URLSearchParams();
  month && params.append('month', month);
  year && params.append('year', year);
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());
  params.append('order', orderBy);
  params.append('sort', sort);
  if (tableOnly) {
    params.append('tableOnly', '1');
  }

  //await new Promise((r) => setTimeout(r, 2000));

  return await FetchProvider.fetch(`/accounting?${params}`);
};

const updateAccountingCashflow = async (data: {
  accounting_id: number;
  cashflow: boolean;
  year?: string;
  month?: string;
  limit: number;
  offset: number;
  orderBy: string;
  sort: string;
}): Promise<{ tableData: IAccountingTableData; dashboard: IAccountingDashboardData }> => {
  return await FetchProvider.fetch(`/accounting/${data.accounting_id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
};

const importFile = async (file: File): Promise<{ success: boolean; message: string }> => {
  const formData = new FormData();
  formData.append('file', file);
  return await FetchProvider.fetchImport(`/accounting`, {
    method: 'POST',
    body: formData,
  });
};

const ApiProvider = {
  getAccounting,
  updateAccountingCashflow,
  importFile,
};

export default ApiProvider;
