import { Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IAccountCNGChartData } from '../../../Types';
import { PERCENTAGE_FORMAT, PERCENTAGE_WITHOUT_DECIMAL_FORMAT } from '../../../../../Types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Plus Value',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return PERCENTAGE_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return PERCENTAGE_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  globalChartData: IAccountCNGChartData;
};

function GraphGlobalPercentage({ globalChartData }: Props) {
  const data = {
    labels: globalChartData.data.map((item) => item.date),
    datasets: [
      {
        fill: true,
        label: 'Plus Value',
        data: globalChartData.data.map((item) => {
          return item.total.variation;
        }),
        borderWidth: 0,
        backgroundColor: 'rgb(14, 164, 122)',
        tension: 0.8,
      },
    ],
  };

  return (
    <Row className="mb-3">
      <Line options={options} data={data} />
    </Row>
  );
}

export default GraphGlobalPercentage;
