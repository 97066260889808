import { useParams } from 'react-router-dom';
import { Col, Row, Placeholder } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT, CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT, PERCENTAGE_FORMAT } from '../../../Types';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, ArcElement, Title, Tooltip } from 'chart.js';
import { useEffect, useReducer } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { IAccountingDashboardData } from '../Types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const options_pie = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return PERCENTAGE_FORMAT.format(context.raw / 100);
        },
      },
    },
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Dépense',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

interface State {
  pieChartData?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
  depenseChartData?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  };
}

type Props = {
  dashboard: IAccountingDashboardData;
};

function Dashboard({ dashboard }: Props) {
  const { month, year } = useParams();

  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {}
  );

  useEffect(() => {
    const pieChartData = {
      labels: ['Dépense', 'Épargne'],
      datasets: [
        {
          label: '%',
          data: [
            -(Number(dashboard.kpiData.depense) / Number(dashboard.kpiData.recette)) * 100,
            (Number(dashboard.kpiData.epargne) / Number(dashboard.kpiData.recette)) * 100,
          ],
          backgroundColor: ['#f8d7da', '#cff4fc'],
          borderColor: ['#f5c2c7', '#b6effb'],
        },
      ],
    };

    let depenseChartData;

    if (month || (year == undefined && month == undefined)) {
      if (dashboard.barChartData && dashboard.barChartData.length > 0) {
        depenseChartData = {
          labels: dashboard.barChartData.map((item) =>
            new Date(item.date).toLocaleString('fr-FR', {
              weekday: 'long',
              day: '2-digit',
              month: 'long',
            })
          ),
          datasets: [
            {
              label: 'Dépense',
              data: dashboard.barChartData.map((item) => (item.total === null ? 0 : item.total)),
              backgroundColor: '#842029',
            },
          ],
        };
      }
    }

    setState({ pieChartData: pieChartData, depenseChartData: depenseChartData });
  }, [dashboard]);

  return (
    <>
      <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
        <Col className="text-primary">
          <h3>Recette</h3>
          <h2>{CURRENCY_EURO_FORMAT.format(Number(dashboard.kpiData.recette || 0))}</h2>
        </Col>
        <Col className="text-danger">
          <h3>Dépense</h3>
          <h2>{CURRENCY_EURO_FORMAT.format(Number(dashboard.kpiData.depense || 0))}</h2>
        </Col>
        <Col className="text-success">
          <h3>Épargne</h3>
          <h2>{CURRENCY_EURO_FORMAT.format(Number(dashboard.kpiData.epargne || 0))}</h2>
        </Col>
      </Row>
      <Row xs="1" sm="3" className="mb-3 justify-content-sm-center">
        <Col>{state.pieChartData && <Pie options={options_pie} data={state.pieChartData} />}</Col>
      </Row>
      <Row xs="1" className="mb-3">
        <Col>{state.depenseChartData && <Bar options={options} data={state.depenseChartData} />}</Col>
      </Row>
    </>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
      <Col className="text-primary">
        <h3>Recette</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-danger">
        <h3>Dépense</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-success">
        <h3>Épargne</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
    </Row>
  );
}

Dashboard.Placeholder = ComponentPlaceholder;

export default Dashboard;
