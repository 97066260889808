import { Button, Placeholder } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT } from '../../../../../Types';
import { PinelCashflowForm, PinelCashflowItem } from '../../../Types';
import { CURRENT_YEAR, getRandom } from '../../../../../Helpers';
import { PAGE_SIZE } from '..';

type Props = {
  tableData: {
    rows: PinelCashflowItem[];
    totalCount: number;
  };
  setCashflowToAddEdit: ({}: PinelCashflowForm) => void;
};

function TableBody({ tableData, setCashflowToAddEdit }: Props) {
  if (tableData.rows.length > 0) {
    return (
      <tbody>
        {tableData.rows.map((item, index) => {
          const isDisabled = CURRENT_YEAR - 1 > new Date(item.date).getFullYear();
          return (
            <tr key={index}>
              <td className="align-middle">{item.date}</td>
              <td className="align-middle">{item.label}</td>
              <td
                className={`align-middle ${item.credit ? 'text-success' : 'text-danger'}`}
              >{`${CURRENCY_EURO_FORMAT.format(item.credit ? item.amount : -item.amount)}`}</td>
              <td>
                <div className="d-grid">
                  <Button
                    disabled={isDisabled}
                    onClick={() => {
                      setCashflowToAddEdit({
                        pinel_cashflow_id: item.pinel_cashflow_id,
                        credit: item.credit,
                        amount: item.amount,
                        label: item.label,
                        date: item.date,
                      });
                    }}
                    className="btn-block"
                    variant="warning"
                  >
                    Modifier
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  } else {
    return (
      <tbody>
        <tr>
          <td colSpan={6} className="align-middle">
            Pas de transaction
          </td>
        </tr>
      </tbody>
    );
  }
}

function ComponentPlaceholder() {
  return (
    <tbody>
      {Array.from(Array(PAGE_SIZE).keys()).map((_, index) => (
        <Placeholder key={index} as={'tr'} animation="glow">
          <td>
            <Placeholder xs={getRandom(6, 12)} />
          </td>
          <td>
            <Placeholder xs={getRandom(6, 12)} />
          </td>
          <td>
            <Placeholder xs={getRandom(6, 12)} />
          </td>
          <td>
            <Placeholder.Button variant="warning" xs={12} />
          </td>
        </Placeholder>
      ))}
    </tbody>
  );
}

TableBody.Placeholder = ComponentPlaceholder;

export default TableBody;
