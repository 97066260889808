import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useFetcher } from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import { Button } from 'react-bootstrap';

function Header() {
  let fetcher = useFetcher();
  let isLoggingOut = fetcher.formData != null;
  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          DI2PRA - Mes App
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} end to="/">
              Accueil
            </Nav.Link>
            {AuthProvider.isUserSignedInAndValid() && (
              <>
                <Nav.Link as={NavLink} to="/pinel">
                  Pinel
                </Nav.Link>
                <Nav.Link as={NavLink} to="/comptabilite">
                  Comptabilité
                </Nav.Link>
                <Nav.Link as={NavLink} to="/investissement">
                  Investissement
                </Nav.Link>
                <Nav.Link as={NavLink} to="/crypto">
                  Crypto
                </Nav.Link>
              </>
            )}
          </Nav>
          {AuthProvider.isUserSignedInAndValid() ? (
            <Nav>
              <fetcher.Form method="post" action="/logout">
                <Nav.Link as={Button} variant="link" type="submit" disabled={isLoggingOut}>
                  Se déconnecter
                </Nav.Link>
              </fetcher.Form>
            </Nav>
          ) : (
            <Nav>
              <Nav.Link as={NavLink} to="/login">
                Se connecter
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
