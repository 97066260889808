import { Button, Placeholder } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT } from '../../../../Types';
import { AccountingItem } from '../../Types';
import { getRandom } from '../../../../Helpers';
import { useReducer } from 'react';

type Props = {
  item: AccountingItem;
  onUpdateRow: (accountingItem: AccountingItem) => Promise<void>;
};

type State = {
  isLoading: boolean;
};

function TableRow({ item, onUpdateRow }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isLoading: false,
    }
  );

  return (
    <tr>
      <td className="align-middle">{item.date}</td>
      <td className="align-middle">{item.label}</td>
      <td className="align-middle" style={{ maxWidth: '200px' }}>
        <span style={{ wordBreak: 'break-all' }}>{item.description}</span>
      </td>
      <td
        className={`align-middle ${
          item.cashflow === true ? (Number(item.amount) > 0 ? 'text-success' : 'text-danger') : 'text-secondary'
        }`}
      >
        {`${CURRENCY_EURO_FORMAT.format(Number(item.amount))}`}
      </td>
      <td className="align-middle">
        <div className="d-grid">
          <Button
            className="btn-block d-flex flex-row gap-2 align-items-center justify-content-center"
            variant={item.cashflow ? 'warning' : 'info'}
            onClick={() => {
              setState({ isLoading: true });
              onUpdateRow(item).then(() => {
                setState({ isLoading: false });
              });
            }}
            disabled={state.isLoading}
          >
            {state.isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span>Mise à jour...</span>
              </>
            ) : item.cashflow ? (
              'Enlever du Cashflow'
            ) : (
              'Ajouter au Cashflow'
            )}
          </Button>
        </div>
      </td>
    </tr>
  );
}

function ComponentPlaceHolder() {
  return (
    <Placeholder as={'tr'} animation="glow">
      <td className="align-middle">
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td className="align-middle">
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td className="align-middle">
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td className="align-middle">
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td className="align-middle">
        <Placeholder.Button variant="success" xs={12} />
      </td>
    </Placeholder>
  );
}

TableRow.Placeholder = ComponentPlaceHolder;

export default TableRow;
