import { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { PinelCashflowChartData, PinelCashflowDashboardData, PinelCashflowForm, PinelCashflowItem } from '../../Types';
import ApiProvider from '../../ApiProvider';
import { PAGE_SIZE } from '.';

type Props = {
  cashflowToAddEdit?: PinelCashflowForm;
  callbackFunction: ({
    chartData,
    dashboardData,
    tableData,
  }: {
    chartData: PinelCashflowChartData;
    dashboardData: PinelCashflowDashboardData;
    tableData: {
      rows: PinelCashflowItem[];
      totalCount: number;
    };
  }) => void;
  offset: number;
  cancelCallbackFunction: () => void;
};

type State = {
  isProcessing: boolean;
  error: string | null;
  success: string | null;
  formData?: PinelCashflowForm;
};

function AddEditCashflowForm({ cashflowToAddEdit, callbackFunction, cancelCallbackFunction, offset }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isProcessing: false,
      error: null,
      success: null,
    }
  );

  useEffect(() => {
    if (cashflowToAddEdit) {
      setState({ formData: cashflowToAddEdit });
    }
  }, [cashflowToAddEdit]);

  const onFormCheckboxHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setState({
        // @ts-ignore
        formData: {
          ...state.formData,
          // @ts-ignore
          [event.target.name]: !state.formData[event.target.name],
        },
      });
    },
    [state.formData]
  );

  const onFormInputHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
      let value: number | string | Date | null = event.target.value;

      if (event.target.tagName === 'SELECT') {
        value = event.target.value === '' ? null : Number(event.target.value);
      }

      setState({
        formData: {
          ...state.formData,
          [event.target.name]: value,
        } as PinelCashflowForm,
      });
    },
    [state.formData]
  );

  const handleFormSubmit = useCallback(
    async (cashflow: PinelCashflowForm) => {
      setState({ isProcessing: true });

      ApiProvider.addOrUpdateCashflow({
        cashflow,
        limit: PAGE_SIZE,
        offset: offset,
      })
        .then((data) => {
          callbackFunction(data.data);
          setState({ success: data.message, error: undefined });
        })
        .catch((err) => {
          setState({ error: err.message });
        })
        .finally(() => {
          setState({ isProcessing: false });
        });
    },
    [callbackFunction, offset]
  );

  const onCancelHandler = useCallback(() => {
    setState({
      formData: undefined,
      isProcessing: false,
      error: null,
      success: null,
    });
    cancelCallbackFunction();
  }, [cancelCallbackFunction]);

  return (
    <Modal size="lg" backdrop="static" show={state.formData === undefined ? false : true} onHide={onCancelHandler}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(state.formData!);
        }}
      >
        <Modal.Header closeButton={!state.isProcessing}>
          {state.formData && (
            <Modal.Title>
              {state.formData.pinel_cashflow_id === null ? `Ajouter une transaction` : `Modifier une transaction`}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.success && (
            <Alert className="mb-0" variant="success">
              {state.success}
            </Alert>
          )}
          {state.formData && !state.success && (
            <>
              <Form.Group className="mb-3" controlId="creditInput">
                <Form.Label>Crédit</Form.Label>
                <Form.Check
                  type="switch"
                  id="credit-switch"
                  name="credit"
                  onChange={onFormCheckboxHandler}
                  checked={state.formData.credit}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="libelleInput">
                <Form.Label>Libellé</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Loyer 2022"
                  onChange={onFormInputHandler}
                  name="label"
                  value={state.formData.label}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="dateInput">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  onChange={onFormInputHandler}
                  name="date"
                  defaultValue={state.formData.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="montantInput">
                <Form.Label>Montant</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="any"
                  min="1"
                  onChange={onFormInputHandler}
                  name="amount"
                  value={state.formData.amount}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={state.isProcessing}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Fermer
          </Button>
          {!state.success && (
            <Button
              variant="primary"
              type="submit"
              className="d-flex flex-row gap-2 align-items-center"
              disabled={state.isProcessing}
            >
              {state.isProcessing ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span>Enregistrement...</span>
                </>
              ) : (
                'Enregistrer'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditCashflowForm;
