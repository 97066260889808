import { FetchProvider } from '../../providers/FetchProvider';
import {
  PinelCashflowChartData,
  PinelCashflowDashboardData,
  PinelCashflowForm,
  PinelCashflowItem,
  PinelFicheImpotData,
  PinelRefImpotFiche,
  PinelRefTransactionType,
  PinelTransactionForm,
  PinelTransactionItem,
} from './Types';

const getFicheImpot = async ({
  year,
  limit,
  offset,
}: {
  year?: number;
  limit: number;
  offset: number;
}): Promise<{
  reference: {
    impotFiche: PinelRefImpotFiche[];
    transactionType: PinelRefTransactionType[];
  };
  availableYears: number[];
  ficheImpotData: PinelFicheImpotData[] | null;
  tableData: {
    rows: PinelTransactionItem[];
    totalCount: number;
  };
}> => {
  const params = new URLSearchParams();
  year && params.append('year', year.toString());
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  return await FetchProvider.fetch(`/pinel/fiche-impot?${params}`);
};

const getCompta = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): Promise<{
  chartData: PinelCashflowChartData;
  dashboardData: PinelCashflowDashboardData;
  tableData: {
    rows: PinelCashflowItem[];
    totalCount: number;
  };
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  return await FetchProvider.fetch(`/pinel/comptabilite?${params}`);
};

const addOrUpdateTransaction = async ({
  transaction,
  year,
  offset,
  limit,
}: {
  transaction: PinelTransactionForm;
  year?: number;
  offset: number;
  limit: number;
}): Promise<{
  success: boolean;
  message: string;
  data: {
    availableYears: number[];
    ficheImpotData: PinelFicheImpotData[];
    tableData: {
      rows: PinelTransactionItem[];
      totalCount: number;
    };
  };
}> => {
  const params = new URLSearchParams();
  year && params.append('year', year.toString());
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (transaction.pinel_impot_transaction_id) {
    return await FetchProvider.fetch(
      `/pinel/fiche-impot/transaction/${transaction.pinel_impot_transaction_id}?${params}`,
      {
        method: 'PUT',
        body: JSON.stringify(transaction),
      }
    );
  } else {
    return await FetchProvider.fetch(`/pinel/fiche-impot/transaction?${params}`, {
      method: 'POST',
      body: JSON.stringify(transaction),
    });
  }
};

const addOrUpdateCashflow = async ({
  cashflow,
  offset,
  limit,
}: {
  cashflow: PinelCashflowForm;
  offset: number;
  limit: number;
}): Promise<{
  success: boolean;
  message: string;
  data: {
    chartData: PinelCashflowChartData;
    dashboardData: PinelCashflowDashboardData;
    tableData: {
      rows: PinelCashflowItem[];
      totalCount: number;
    };
  };
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (cashflow.pinel_cashflow_id) {
    return await FetchProvider.fetch(`/pinel/comptabilite/transaction/${cashflow.pinel_cashflow_id}?${params}`, {
      method: 'PUT',
      body: JSON.stringify(cashflow),
    });
  } else {
    return await FetchProvider.fetch(`/pinel/comptabilite/transaction?${params}`, {
      method: 'POST',
      body: JSON.stringify(cashflow),
    });
  }
};

const ApiProvider = {
  getFicheImpot,
  getCompta,
  addOrUpdateTransaction,
  addOrUpdateCashflow,
};

export default ApiProvider;
