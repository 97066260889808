import { Col, Placeholder, Row } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../Types';
import { IAccountCGDashboard } from '../../Types';

type Props = {
  dashboard: IAccountCGDashboard;
};

function GlobalKPI({ dashboard }: Props) {
  return (
    <Row xs={1} sm={2} lg={4} className="mb-3 text-center gy-3">
      <Col className="text-primary">
        <h3>Solde</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(dashboard.total_amount)}</h2>
      </Col>
      <Col className="text-success">
        <h3>Intérêts acquis net</h3>
        <h2>
          {CURRENCY_EURO_FORMAT.format(dashboard.total_interest - dashboard.total_impot)} (
          {PERCENTAGE_FORMAT.format((dashboard.total_interest - dashboard.total_impot) / dashboard.total_amount)})
        </h2>
      </Col>
      <Col className="text-danger">
        <h3>Impôts</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(dashboard.total_impot)}</h2>
      </Col>
      <Col>
        <h3>Taux intérêt net</h3>
        <h2>{PERCENTAGE_FORMAT.format(dashboard.current_yeld)}</h2>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={4} className="mb-3 text-center gy-3">
      <Col className="text-primary">
        <h3>Solde</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-success">
        <h3>Intérêts acquis net</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-danger">
        <h3>Impôts</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col>
        <h3>Taux intérêt net</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
    </Row>
  );
}

GlobalKPI.Placeholder = ComponentPlaceholder;

export default GlobalKPI;
