import { IS_LOCAL_DEV } from '../App';
import { AuthProvider } from './AuthProvider';

const HOST = process.env.REACT_APP_API_HOST ? `https://${process.env.REACT_APP_API_HOST}` : '';

const BASE_PATH = `/api/v1`;

interface FetchProvider {
  fetch: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<any>;
  fetchImport: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<any>;
}

export const FetchProvider: FetchProvider = {
  fetch: async (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<any> => {
    let requestInit: RequestInit = {};

    const headers = new Headers(init?.headers);

    if (!IS_LOCAL_DEV) {
      const token = await AuthProvider.getAccessToken();
      headers.append('Authorization', `Bearer ${token.getJwtToken()}`);
    }

    headers.append('Content-Type', `application/json; charset=utf-8`);

    requestInit = {
      ...init,
      headers,
    };

    const response = await fetch(`${HOST}${BASE_PATH}${input}`, requestInit);

    const body = await response.json();

    if (response.ok) {
      return body;
    } else {
      if (body.message) {
        throw Error(body.message);
      } else {
        throw Error('Undefined');
      }
    }
  },
  fetchImport: async (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<any> => {
    const headers = new Headers(init?.headers);

    const token = await AuthProvider.getAccessToken();
    headers.append('Authorization', `Bearer ${token.getJwtToken()}`);

    const initWithAuth: RequestInit = {
      ...init,
      headers,
    };

    const response = await fetch(`${HOST}${BASE_PATH}${input}`, initWithAuth);

    const body = await response.json();

    if (response.ok) {
      return body;
    } else {
      if (body.message) {
        throw Error(body.message);
      } else {
        throw Error('Undefined');
      }
    }
  },
};
