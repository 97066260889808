import { Col, Placeholder, Row, Table } from 'react-bootstrap';
import { PinelFicheImpotData } from '../../Types';
import { getRandom } from '../../../../Helpers';
import { CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT } from '../../../../Types';

type Props = {
  ficheImpotData: PinelFicheImpotData[] | null;
};

function FicheImpotTable({ ficheImpotData }: Props) {
  const totalAmount = (ficheImpotData || []).reduce((a, b) => {
    return b.credit ? a + Math.floor(Number(b.total)) : a - Math.floor(Number(b.total));
  }, 0);

  if (ficheImpotData) {
    return (
      <Row className="justify-content-md-center">
        <Col lg={8}>
          <Table className="text-center" striped responsive>
            <thead>
              <tr>
                <th>Case</th>
                <th style={{ width: '150px' }}>Montant</th>
              </tr>
            </thead>
            <tbody>
              {ficheImpotData.map((item, index) => {
                let amount = Math.floor(Number(item.total));

                amount = amount !== 0 ? (item.credit ? amount : -amount) : amount;

                return (
                  <tr key={index}>
                    <td className="align-middle">{`${item.label} (${item.case_impot})`}</td>
                    <td
                      className={`align-middle ${amount !== 0 ? (item.credit ? 'text-success' : 'text-danger') : ''}`}
                    >{`${CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(amount)}`}</td>
                  </tr>
                );
              })}
              <tr>
                <td className="align-middle fw-bold">Total</td>
                <td className="align-middle fw-bold">{CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(totalAmount)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  return <ComponentPlaceholder />;
}

function ComponentPlaceholder() {
  return (
    <Row className="justify-content-md-center">
      <Col lg={8}>
        <Table className="text-center" striped responsive>
          <thead>
            <tr>
              <th>Case</th>
              <th style={{ width: '150px' }}>Montant</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(10).keys()).map((_, index) => (
              <Placeholder key={index} as={'tr'} animation="glow">
                <td>
                  <Placeholder xs={getRandom(6, 12)} />
                </td>
                <td>
                  <Placeholder xs={getRandom(6, 12)} />
                </td>
              </Placeholder>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

FicheImpotTable.Placeholder = ComponentPlaceholder;

export default FicheImpotTable;
