import { Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IAccountCNGChartData } from '../../../Types';
import { CURRENCY_EURO_FORMAT, CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT } from '../../../../../Types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      stacked: 'single' as boolean | 'single' | undefined,
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  globalChartData: IAccountCNGChartData;
};

function GraphParCompte({ globalChartData }: Props) {
  const data = {
    labels: globalChartData.data.map((item) => item.date),
    datasets: globalChartData.accountList.map((account) => {
      return {
        fill: true,
        label: account.name,
        data: globalChartData.data.map((item) => {
          let current_value: any = 0;

          current_value = item.data.find((col) => col.account_cng_id === account.account_cng_id)?.current_value;

          return current_value;
        }),
        borderWidth: 0,
        backgroundColor: account.color,
        tension: 0.8,
      };
    }),
  };

  return (
    <Row className="mb-3">
      <Line options={options} data={data} />
    </Row>
  );
}

export default GraphParCompte;
