import { Col, Placeholder, Row } from 'react-bootstrap';
import { IAccountCG } from '../../../Types';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../../Types';

type Props = {
  account: IAccountCG;
};

function AccountKPI({ account }: Props) {
  const tauxInteretNet = account.taux_impot ? (account.taux_interet * (1 - account.taux_impot / 100)) / 100 : null;
  const tauxInteretNetLabel = tauxInteretNet ? ` (${PERCENTAGE_FORMAT.format(tauxInteretNet)} net)` : '';

  const interetNetAcquis = account.taux_impot
    ? ` (${CURRENCY_EURO_FORMAT.format(account.total_interest - account.total_impot)} net)`
    : '';

  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
      <Col className="text-primary">
        <h3>Solde</h3>
        <h3>{CURRENCY_EURO_FORMAT.format(account.total_amount)}</h3>
      </Col>
      <Col className="text-success">
        <h3>Intérêts acquis</h3>
        <h3>
          {CURRENCY_EURO_FORMAT.format(account.total_interest)}
          {interetNetAcquis}
        </h3>
      </Col>
      <Col>
        <h3>Taux intérêt</h3>
        <h3>
          {PERCENTAGE_FORMAT.format(account.taux_interet / 100)}
          {tauxInteretNetLabel}
        </h3>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
      <Col className="text-primary">
        <h3>Solde</h3>
        <h3>
          <Placeholder xs={6} />
        </h3>
      </Col>
      <Col className="text-success">
        <h3>Intérêts acquis</h3>
        <h3>
          <Placeholder xs={6} />
        </h3>
      </Col>
      <Col>
        <h3>Taux intérêt</h3>
        <h3>
          <Placeholder xs={6} />
        </h3>
      </Col>
    </Row>
  );
}

AccountKPI.Placeholder = ComponentPlaceholder;

export default AccountKPI;
