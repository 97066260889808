import React, { useCallback, useReducer } from 'react';
import { Form, Modal, Button, Alert } from 'react-bootstrap';
import ApiProvider from '../ApiProvider';
import { useNavigate } from 'react-router-dom';

type Props = {
  displayForm: boolean;
  closeForm: () => void;
};

type State = {
  isProcessing: boolean;
  file?: File;
  error: string | null;
  success: string | null;
};

function ImportForm({ displayForm, closeForm }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isProcessing: false,
      error: null,
      success: null,
    }
  );

  const navigate = useNavigate();

  const onCancelHandler = useCallback(() => {
    if (state.success) {
      navigate('/comptabilite');
    }
    setState({ success: null, error: null });
    closeForm();
  }, [state.success]);

  const onFileChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setState({ file: event.target.files[0] });
    }
  }, []);

  const onSubmitHandler = useCallback(() => {
    setState({ isProcessing: true, error: null });

    if (state.file === undefined) {
      setState({ error: 'File not found', isProcessing: false });
    } else {
      ApiProvider.importFile(state.file)
        .then((data) => {
          setState({ success: data.message });
        })
        .catch((error) => {
          setState({ error: error.message });
        })
        .finally(() => setState({ isProcessing: false }));
    }
  }, [state.file]);

  return (
    <>
      <Modal
        show={displayForm}
        onHide={() => {
          onCancelHandler();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!state.isProcessing}>
          <Modal.Title>Importer des données</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.success ? (
            <Alert className="mb-0" variant="success">
              {state.success}
            </Alert>
          ) : (
            <Form.Group controlId="formFile">
              <Form.Label>Select the CSV file</Form.Label>
              <Form.Control type="file" disabled={state.isProcessing} onChange={onFileChangeHandler} accept=".csv" />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onCancelHandler();
            }}
            disabled={state.isProcessing}
          >
            Fermer
          </Button>
          {state.success == null && (
            <Button
              variant="primary"
              onClick={() => {
                onSubmitHandler();
              }}
              disabled={state.isProcessing || state.file === undefined}
            >
              {state.isProcessing ? 'Importation en cours...' : 'Soumettre'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportForm;
