import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { IS_LOCAL_DEV } from '../App';

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '', // Your user pool id here
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '', // Your client id here
};

interface AuthProvider {
  currentUser: CognitoUser | null;
  initUserLogin: () => Promise<void>;
  logIn({ Username, Password }: { Username: string; Password: string }): Promise<CognitoUserSession | void>;
  logOut: () => Promise<void>;
  getAccessToken: () => Promise<CognitoAccessToken>;
  updateAuthSession: () => Promise<void>;
  isUserSignedInAndValid: () => boolean;
}

export const AuthProvider: AuthProvider = {
  currentUser: null,
  initUserLogin: () =>
    new Promise<void>((resolve, _) => {
      const userPool = new CognitoUserPool(poolData);
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        let session = currentUser.getSignInUserSession();
        if (session && session.isValid()) {
          AuthProvider.currentUser = currentUser;
          resolve();
        }

        currentUser.getSession((_: null, __: CognitoUserSession) => {
          AuthProvider.currentUser = currentUser;
          resolve();
        });
      } else {
        resolve();
      }
    }),
  logIn: async ({ Username, Password }: { Username: string; Password: string }): Promise<CognitoUserSession | void> => {
    const authenticationDetails = new AuthenticationDetails({
      Username,
      Password,
    });

    const userPool = new CognitoUserPool(poolData);

    const userData = {
      Username: Username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    await new Promise(function (resolve: (value: CognitoUserSession) => void, reject) {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          AuthProvider.currentUser = cognitoUser;
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
        newPasswordRequired: function (_, __) {
          reject(Error('New Password Required'));
        },
        mfaRequired: function (_, __) {
          reject(Error('MFA Required'));
        },
        totpRequired: function (_, __) {
          reject(Error('TOTP Required'));
        },
        customChallenge: (_) => {
          reject(Error('Unknown Error'));
        },
        mfaSetup: function (_, __) {
          reject(Error('Unknown Error'));
        },
        selectMFAType: function (_, __) {
          reject(Error('Unknown Error'));
        },
      });
    });
  },
  logOut: async () => {
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();

    if (currentUser) {
      await new Promise<void>(function (resolve, _) {
        currentUser.signOut(() => {
          AuthProvider.currentUser = null;
          resolve();
        });
      });
    }
  },
  getAccessToken: async () => {
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      const session = currentUser.getSignInUserSession();

      if (session && session.isValid()) {
        return session.getAccessToken();
      } else {
        return await new Promise<CognitoAccessToken>((resolve, reject) => {
          if (currentUser) {
            currentUser.getSession((_: null, session: CognitoUserSession) => {
              resolve(session.getAccessToken());
            });
          } else {
            reject('User authentication issue');
          }
        });
      }
    } else {
      throw Error('User not authenticated');
    }
  },
  updateAuthSession: async () => {
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      const session = currentUser.getSignInUserSession();

      if (session && session.isValid()) {
        return;
      }
      currentUser.getSession((_: null, __: CognitoUserSession) => {});
    }
  },
  isUserSignedInAndValid: () => {
    if (IS_LOCAL_DEV) {
      return true;
    }

    if (AuthProvider.currentUser == null) {
      return false;
    } else {
      return true;
    }
  },
};
