export const useFetch = <A>(url: string) => {
  let status = 'pending';
  let result: any;
  let suspender = new Promise<void>((resolve, reject) => {
    fetch(url)
      .then((r) => r.json())
      .then((r) => {
        status = 'success';
        result = r;
        resolve();
      })
      .catch((e) => {
        status = 'error';
        result = e;
        reject();
      });
  });

  const resource: Resource<A> = {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };

  return resource;
};

export interface Resource<A> {
  read: () => never | A;
}

export const wrapPromise = <A>(promise: Promise<A>) => {
  let result: 'pending' | Error | A = 'pending';
  const setResult = (newResult: typeof result) => {
    result = newResult;
  };

  const suspender = promise.then(setResult).catch(setResult);

  const resource: Resource<A> = {
    read() {
      if (result === 'pending') {
        throw suspender;
      } else if (result instanceof Error) {
        console.log('result instanceof Error');
        throw result;
      } else {
        return result;
      }
    },
  };

  return resource;
};

/**
 * Get Random number between 2 values
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export const getRandom = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const accumulate = (array: any[]) =>
  array.map(
    (
      (sum) => (value) =>
        (sum += value)
    )(0)
  );

export const CURRENT_YEAR = new Date().getFullYear();

/**
 * Add weeks to Date
 * @param {Date} date
 * @param {number} numberOfWeeks
 * @return {Date}
 */
export function addWeeksToDate(date: Date, numberOfWeeks: number): Date {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + numberOfWeeks * 7);
  return newDate;
}
