import { Col, Placeholder, Row } from 'react-bootstrap';
import { IAccountCNG } from '../../../Types';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../../Types';

type Props = {
  account: IAccountCNG;
};

function AccountKPI({ account }: Props) {
  return (
    <Row xs={1} sm={3} lg={5} className="mb-4 mt-2 gy-3 text-center">
      <Col className="text-primary">
        <h5>Situation</h5>
        <h4>{CURRENCY_EURO_FORMAT.format(account.last_current_value)}</h4>
      </Col>
      <Col className="text-info">
        <h5>Investissement</h5>
        <h4>{CURRENCY_EURO_FORMAT.format(account.last_invested_amount)}</h4>
      </Col>
      <Col className={`${account.last_return < 0 ? 'text-danger' : 'text-success'}`}>
        <h5>{account.last_return < 0 ? 'Moins Value' : 'Plus Value'}</h5>
        <h4>
          {CURRENCY_EURO_FORMAT.format(account.last_return)}
          {` `} ({PERCENTAGE_FORMAT.format(account.last_return_percentage)})
        </h4>
      </Col>
      <Col className="text-secondary">
        <h5>YtD MWR (Rendement)</h5>
        <h4>{PERCENTAGE_FORMAT.format(account.moneyWeightedReturn)}</h4>
      </Col>
      <Col className="text-secondary">
        <h5>YtD TWR (Performance)</h5>
        <h4>{PERCENTAGE_FORMAT.format(account.timeWeightedReturn)}</h4>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={3} lg={5} className="mb-4 mt-2 gy-3 text-center">
      <Col className="text-primary">
        <h5>Situation</h5>
        <h4>
          <Placeholder xs={6} />
        </h4>
      </Col>
      <Col className="text-info">
        <h5>Investissement</h5>
        <h4>
          <Placeholder xs={6} />
        </h4>
      </Col>
      <Col className="text-success">
        <h5>Plus / Moins Value</h5>
        <h4>
          <Placeholder xs={6} />
        </h4>
      </Col>
      <Col className="text-secondary">
        <h5>YtD MWR (Rendement)</h5>
        <h4>
          <Placeholder xs={6} />
        </h4>
      </Col>
      <Col className="text-secondary">
        <h5>YtD TWR (Performance)</h5>
        <h4>
          <Placeholder xs={6} />
        </h4>
      </Col>
    </Row>
  );
}

AccountKPI.Placeholder = ComponentPlaceholder;

export default AccountKPI;
