import { Col, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <h1>Comptabilité</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}
export default Layout;
