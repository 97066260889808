import { Col, Placeholder, Row } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../Types';
import { TickerData } from '.';
import { CryptoData } from '../Types';

type Props = {
  tickerData: TickerData;
  cryptoData: CryptoData;
};

function TickerCard({ tickerData, cryptoData }: Props) {
  const euros = Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: cryptoData.minimum_fraction_digits || undefined,
  });

  const PRU = parseFloat(cryptoData.total_invested_amount) / cryptoData.value;
  const variation =
    (cryptoData.value * tickerData.price - parseFloat(cryptoData.total_invested_amount)) /
    parseFloat(cryptoData.total_invested_amount);

  return (
    <Row className="gy-4 mb-3">
      <Col>
        <div
          className={`p-3 rounded-3 ${variation > 0 ? 'text-sucess-emphasis bg-success-subtle' : 'text-danger-emphasis bg-danger-subtle'}`}
        >
          <div className="hstack gap-3">
            <div>
              <h2>{cryptoData.label}</h2>
              <div className="d-md-none mb-2">
                <h4 className="mb-1">{euros.format(tickerData.price)}</h4>
                <h6>{PERCENTAGE_FORMAT.format(variation)}</h6>
              </div>
              <div className="d-flex gap-3">
                <div>
                  <div className="mb-2 mb-md-1">
                    <h6 className="m-0 mb-1 mb-md-0 text-secondary d-md-inline-block me-md-2">PRU :</h6>
                    <h6 className="mb-0 d-md-inline-block">{euros.format(PRU)}</h6>
                  </div>
                  <div>
                    <h6 className="m-0 mb-1 mb-md-0 text-secondary d-md-inline-block me-md-2">Quantity :</h6>
                    <h6 className="d-md-inline-block">{cryptoData.value}</h6>
                  </div>
                  {cryptoData.dca_average && (
                    <div>
                      <h6 className="m-0 mb-1 mb-md-0 text-secondary d-md-inline-block me-md-2">DCA :</h6>
                      <h6 className="d-md-inline-block">{euros.format(cryptoData.dca_average)}</h6>
                    </div>
                  )}
                </div>
                <div className="vr"></div>
                <div>
                  <div className="mb-2 mb-md-1">
                    <h6 className="m-0 mb-1 mb-md-0 text-secondary d-md-inline-block me-md-2">Total Amount :</h6>
                    <h6 className="mb-0 d-md-inline-block">
                      {CURRENCY_EURO_FORMAT.format(parseFloat(cryptoData.total_invested_amount))}
                    </h6>
                  </div>
                  <div>
                    <h6 className="m-0 mb-1 mb-md-0 text-secondary d-md-inline-block me-md-2">Current Value :</h6>
                    <h6 className="d-md-inline-block">
                      {CURRENCY_EURO_FORMAT.format(cryptoData.value * tickerData.price)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-auto text-end d-none d-md-block">
              <h3>{euros.format(tickerData.price)}</h3>
              <h5>{PERCENTAGE_FORMAT.format(variation)}</h5>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

function ComponentPlacholder() {
  return (
    <Row className="gy-4 mb-3">
      <Col>
        <div className={`p-3 rounded-3 text-secondary-emphasis bg-secondary-subtle`}>
          <div className="hstack gap-3">
            <div>
              <Placeholder size="lg" xs="6" className="mb-4" />
              <div className="d-md-none mb-2">
                <Placeholder xs="8" />
                <Placeholder xs="8" />
              </div>
              <div className="d-flex gap-3">
                <div className="vstack gap-2" style={{ width: '150px' }}>
                  <Placeholder style={{ width: '100%' }} />
                  <Placeholder style={{ width: '100%' }} />
                </div>
                <div className="vr"></div>
                <div className="vstack gap-2" style={{ width: '150px' }}>
                  <Placeholder style={{ width: '100%' }} />
                  <Placeholder style={{ width: '100%' }} />
                </div>
              </div>
            </div>
            <div className="ms-auto text-end d-none d-md-block">
              <Placeholder xs="8" size="lg" style={{ width: '10rem' }} className="d-block" />
              <Placeholder xs="8" size="lg" style={{ width: '3rem' }} className="d-inline-block" />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

TickerCard.Placeholder = ComponentPlacholder;

export default TickerCard;
