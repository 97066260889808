import { Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { IAccountCGGlobalYearOverYearData } from '../../../Types';
import { CURRENCY_EURO_FORMAT, CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT } from '../../../../../Types';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Performance Annuelle',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  globalYearOverYearData: IAccountCGGlobalYearOverYearData[];
};

function GlobalYearOverYearEuros({ globalYearOverYearData }: Props) {
  const data = {
    labels: globalYearOverYearData.map((item) => item.year),
    datasets: [
      {
        label: 'Investissement Moyen',
        data: globalYearOverYearData.map((item) => Number(item.total)),
        backgroundColor: 'rgb(13, 110, 253)',
      },
      {
        label: 'Intérêts',
        data: globalYearOverYearData.map((item) => Number(item.total_interest)),
        backgroundColor: 'rgb(25, 135, 84)',
      },
      {
        label: 'Impôts',
        data: globalYearOverYearData.map((item) => Number(item.total_impot)),
        backgroundColor: 'rgb(220, 53, 69)',
      },
    ],
  };

  return (
    <Row className="mb-3">
      <Bar options={options} data={data} />
    </Row>
  );
}

function ComponentPlaceholder() {
  return <Row className="mb-3"></Row>;
}

GlobalYearOverYearEuros.Placeholder = ComponentPlaceholder;

export default GlobalYearOverYearEuros;
