import React, { ChangeEvent, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Form, Placeholder } from 'react-bootstrap';
import { IAccountingDate } from '../Types';

type Props = {
  dates: IAccountingDate;
  onFilterUpdate: ({ year, month }: { year?: string | undefined; month?: string | undefined }) => Promise<void>;
};

function Filter({ dates, onFilterUpdate }: Props) {
  const { year, month } = useParams();

  const onSelectedYearChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = String(event.target.value);
    onFilterUpdate({ year: selectedYear, month: undefined });
  }, []);

  const onSelectedMonthChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      let selectedMonth: string | undefined = String(event.target.value);

      selectedMonth = selectedMonth == '' ? undefined : selectedMonth;

      const selectedYear = year ? year : dates.years[0];

      onFilterUpdate({ year: selectedYear, month: selectedMonth });
    },
    [year, dates.years]
  );

  return (
    <Row xs={2} sm={2} lg={3} className="mb-3">
      <Col>
        <Form.Group>
          <Form.Select aria-label="Choisir l'année" onChange={onSelectedYearChange} value={year}>
            {dates.years.map((item, key) => {
              return (
                <option key={key} value={item}>
                  {item}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Select
            aria-label="Choisir le mois"
            onChange={onSelectedMonthChange}
            value={month ? month : year ? '' : dates.months[0].split('-')[1]}
          >
            <option value="">--- mois ---</option>
            {dates.months
              .filter((item) => {
                let selectedYear = year ? year : dates.years[0];

                return selectedYear && item.includes(String(selectedYear));
              })
              .map((item, key) => {
                return (
                  <option key={key} value={item.split('-')[1]}>
                    {new Date(item).toLocaleString('default', {
                      month: 'long',
                    })}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={2} sm={2} lg={3} className="mb-3">
      <Col>
        <Placeholder xs={10} />
      </Col>
      <Col>
        <Placeholder size="lg" xs={10} />
      </Col>
    </Row>
  );
}

Filter.Placeholder = ComponentPlaceholder;

export default Filter;
