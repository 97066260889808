import { FetchProvider } from '../../providers/FetchProvider';
import { CryptoDashboard } from './Types';

const getCryptoSummary = async ({ limit, offset }: { limit: number; offset: number }): Promise<CryptoDashboard> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());
  return await FetchProvider.fetch(`/crypto?${params}`);
};

const ApiProvider = {
  getCryptoSummary,
};

export default ApiProvider;
