import {
  Form as FormRouter,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

export async function loginLoader() {
  if (AuthProvider.isUserSignedInAndValid()) {
    return redirect('/');
  }
  return null;
}

export async function loginAction({ request }: LoaderFunctionArgs) {
  let formData = await request.formData();
  let Username = formData.get('username') as string | null;
  let Password = formData.get('password') as string | null;

  // Validate our form inputs and return validation errors via useActionData()
  if (!Username) {
    return {
      error: 'You must provide a username to log in',
    };
  }

  if (!Password) {
    return {
      error: 'You must provide a password to log in',
    };
  }

  // Sign in and redirect to the proper destination if successful.
  try {
    await AuthProvider.logIn({ Username, Password });
  } catch (error) {
    // Unused as of now but this is how you would handle invalid
    // username/password combinations - just like validating the inputs
    // above
    return {
      error: 'Invalid login attempt',
    };
  }

  let redirectTo = formData.get('redirectTo') as string | null;
  return redirect(redirectTo || '/');
}

function LoginPage() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get('from') || '/';

  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get('username') != null;

  let actionData = useActionData() as { error: string } | undefined;

  return (
    <Container className="pt-3 full-height pb-3">
      <Row className="justify-content-md-center">
        <Col xs="12" sm="12" md="8" lg="6" xl="5">
          <Card>
            <Card.Header>Se connecter</Card.Header>
            <Card.Body>
              <FormRouter method="post" replace>
                {actionData && actionData.error ? <Alert variant="danger">{actionData.error}</Alert> : null}
                <input type="hidden" name="redirectTo" value={from} />
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Label>Nom d'utilisateur</Form.Label>
                  <Form.Control
                    name="username"
                    type="text"
                    required
                    autoComplete="username"
                    placeholder="Votre nom d'utilisateur"
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    placeholder="Votre mot de passe"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isLoggingIn}>
                  Se connecter
                </Button>
              </FormRouter>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
