import { Col, Nav, Row } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';

function Layout() {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <h1>Investissement</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/investissement/capital-garanti">
                Capital Garanti
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/investissement/capital-non-garanti">
                Capital Non Garanti
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}
export default Layout;
