import { Placeholder } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT, DATE_TIME_FORMAT } from '../../../../Types';
import { CryptoTransactionItem } from '../../Types';
import { getRandom } from '../../../../Helpers';

type Props = {
  item: CryptoTransactionItem;
};

function TableRow({ item }: Props) {
  return (
    <tr className="align-middle">
      <td>{DATE_TIME_FORMAT.format(new Date(item.executed_on))}</td>
      <td>{item.label}</td>
      <td>{`${CURRENCY_EURO_FORMAT.format(Number(item.amount_invested))}`}</td>
      <td>{`${CURRENCY_EURO_FORMAT.format(Number(item.price))}`}</td>
    </tr>
  );
}

function ComponentPlaceHolder() {
  return (
    <Placeholder className="align-middle" as={'tr'} animation="glow">
      <td>
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td>
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td>
        <Placeholder xs={getRandom(6, 12)} />
      </td>
      <td>
        <Placeholder xs={getRandom(6, 12)} />
      </td>
    </Placeholder>
  );
}

TableRow.Placeholder = ComponentPlaceHolder;

export default TableRow;
