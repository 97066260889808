import { Col, Placeholder, Row } from 'react-bootstrap';
import { PinelCashflowDashboardData } from '../../Types';
import { CURRENCY_EURO_FORMAT } from '../../../../Types';

type Props = {
  dashboardData: PinelCashflowDashboardData;
};

function ComptaDashboard({ dashboardData }: Props) {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
      <Col className="text-danger">
        <h3>Cashflow</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(dashboardData.cashflowTotal)}</h2>
      </Col>
      <Col className="text-warning">
        <h3>Capital Restant dû</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(dashboardData.capitalRestantDu)}</h2>
      </Col>
      <Col className="text-success">
        <h3>Prix de Vente minimum</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(dashboardData.prixDeVenteMin)}</h2>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 text-center gy-3">
      <Col className="text-danger">
        <h3>Cashflow</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-warning">
        <h3>Capital Restant dû</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
      <Col className="text-success">
        <h3>Prix de Vente minimum</h3>
        <h2>
          <Placeholder xs={8} />
        </h2>
      </Col>
    </Row>
  );
}

ComptaDashboard.Placeholder = ComponentPlaceholder;

export default ComptaDashboard;
