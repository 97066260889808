import { Col, Placeholder, Row } from 'react-bootstrap';
import { useEffect, useReducer } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IAccountCNG } from '../../../Types';
import { PERCENTAGE_FORMAT, PERCENTAGE_WITHOUT_DECIMAL_FORMAT } from '../../../../../Types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

type IProps = {
  account: IAccountCNG;
};

type State = {
  chartData?: {
    labels: string[];
    datasets: {
      fill: any;
      label: string;
      data: number[];
      tension: number;
    }[];
  };
  chartOption?: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    scales: {
      y: {
        type: string;
      };
    };
    plugins: {
      tooltip: {
        callbacks: {
          label: any;
        };
      };
    };
  };
};

function AccountChart({ account }: IProps) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {}
  );

  useEffect(() => {
    const chartData = {
      labels: account.status_list.map((item) => item.status_date),
      datasets: [
        {
          fill: {
            target: 'origin',
            below: 'rgb(224, 20, 76)', // Area will be red above the origin
            above: 'rgb(14, 164, 122)', // And blue below the origin
          },
          borderWidth: 0,
          label: 'Variation',
          data: account.status_list.map((item) => item.variation_percentage * 100),
          tension: 0.8,
        },
      ],
    };

    let maxValue = account.status_list
      .map((item) => item.variation_percentage * 100)
      .reduce((a, b) => Math.max(a, b), -Infinity);

    maxValue = maxValue < 0 ? 1 : maxValue;

    let minValue = account.status_list
      .map((item) => item.variation_percentage * 100)
      .reduce((a, b) => Math.min(a, b), +Infinity);

    minValue = minValue > 0 ? 1 : minValue;

    const chartOption = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        y: {
          type: 'linear',
          min: minValue * 1.1,
          max: maxValue * 1.1,
          ticks: {
            // Include a % sign in the ticks
            callback: function (value: any, _: any, __: any) {
              return PERCENTAGE_WITHOUT_DECIMAL_FORMAT.format(value / 100);
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Variation',
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              return PERCENTAGE_FORMAT.format(parseFloat(context.formattedValue.replace(',', '.')) / 100);
            },
          },
        },
      },
    };

    setState({
      chartData,
      chartOption,
    });
  }, [account]);

  return (
    <Row className="mb-3">
      <Col className="offset-md-2 col-md-8">
        {
          // @ts-ignore
          state.chartData && state.chartOption && <Line options={state.chartOption} data={state.chartData} />
        }
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row className="mb-3">
      <Col className="offset-md-2 col-md-8">
        <Placeholder size="lg" xs={12} />
      </Col>
    </Row>
  );
}

AccountChart.Placeholder = ComponentPlaceholder;

export default AccountChart;
