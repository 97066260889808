import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const HOME_CARDS = [
  {
    name: 'Pinel',
    path: 'pinel',
    icon: './icons/pinel.png',
  },
  {
    name: 'Comptabilité',
    path: 'comptabilite',
    icon: './icons/comptabilite.png',
  },
  {
    name: 'Investissement',
    path: 'investissement',
    icon: './icons/investissement.png',
  },
  {
    name: 'Crypto',
    path: 'crypto',
    icon: './icons/crypto.png',
  },
];

export default function HomePage() {
  let navigate = useNavigate();

  return (
    <Row className="my-4 g-3" xs={1} md={2} xl={4}>
      {HOME_CARDS.map((item, index) => {
        return (
          <Col key={index}>
            <Button
              variant="info"
              className="btn-block w-100 d-flex flex-column py-3 px-5 gap-3 justify-content-center align-items-center"
              onClick={() => {
                navigate(`/${item.path}`);
              }}
            >
              <h2>{item.name}</h2>
              <img src={item.icon} alt={item.name} width="100px" />
            </Button>
          </Col>
        );
      })}
    </Row>
  );

  /*return (
    <div className="d-flex gap-5 my-4 flex-wrap">
      {HOME_CARDS.map((item, index) => {
        return (
          <div key={index}>
            <Button
              onClick={() => {
                navigate(`/${item.path}`);
              }}
            >
              <div className="d-flex flex-column my-3 mx-5 gap-3 justify-content-center align-items-center">
                <h2>{item.name}</h2>
                <img src={item.icon} alt={item.name} width="100px" />
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );*/
}
