import { Spinner } from 'react-bootstrap';

type Props = {
  title?: string;
  notFullHeight?: boolean;
};

const Loading = ({ title, notFullHeight }: Props) => {
  return (
    <div
      className={`d-flex flex-column ${
        notFullHeight === true ? '' : 'min-vh-100'
      } justify-content-center align-items-center`}
    >
      <Spinner className="mb-3" animation="border" />
      {title && <h3>{title}</h3>}
    </div>
  );
};

export default Loading;
