import { Col, Row, Table as TableComponent } from 'react-bootstrap';

import TableRow from './TableRow';
import { AccountingItem, IAccountingTableData } from '../../Types';

type Props = {
  tableData: IAccountingTableData;
  onUpdateRow: (accountingItem: AccountingItem) => Promise<void>;
};

function Table({ tableData, onUpdateRow }: Props) {
  return (
    <Row>
      <Col>
        <TableComponent className="text-center" striped responsive>
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Date</th>
              <th style={{ width: '200px' }}>Label</th>
              <th>Description</th>
              <th style={{ width: '150px' }}>Montant</th>
              <th style={{ width: '200px' }}></th>
            </tr>
          </thead>
          <TableBody onUpdateRow={onUpdateRow} tableData={tableData} />
        </TableComponent>
      </Col>
    </Row>
  );
}

function TableBody({ tableData, onUpdateRow }: Props) {
  if (tableData.rows) {
    if (tableData.rows.length > 0) {
      return (
        <tbody className="fs-6">
          {tableData.rows.map((item) => {
            return <TableRow onUpdateRow={onUpdateRow} key={item.accounting_id} item={item} />;
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5} className="align-middle">
              Pas de transaction
            </td>
          </tr>
        </tbody>
      );
    }
  }

  return (
    <tbody>
      <tr>
        <td colSpan={5} className="align-middle">
          Erreur de chargement
        </td>
      </tr>
    </tbody>
  );
}

export default Table;
