import { Alert, Button, Col, Placeholder, Row } from 'react-bootstrap';
import { useCallback, useEffect, useReducer } from 'react';
import ApiProvider from '../../ApiProvider';
import { PinelCashflowChartData, PinelCashflowDashboardData, PinelCashflowForm, PinelCashflowItem } from '../../Types';
import ComptaDashboard from './Dashboard';
import TransactionTable from './TransactionTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddEditCashflowForm from './AddEditCashflowForm';
import Chart from './Chart';

export const PAGE_SIZE: number = 50;

type State = {
  error: string | null;
  data?: {
    chartData: PinelCashflowChartData;
    dashboardData: PinelCashflowDashboardData;
    tableData: {
      rows: PinelCashflowItem[];
      totalCount: number;
    } | null;
  };
  cashflowToAddEdit?: PinelCashflowForm;
};

function Comptabilite() {
  const { page } = useParams();
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    { error: null }
  );

  let location = useLocation();
  const navigate = useNavigate();
  const offset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  useEffect(() => {
    ApiProvider.getCompta({ limit: PAGE_SIZE, offset: offset })
      .then((data) => {
        setState({
          data: {
            ...state.data,
            ...data,
          },
        });
      })
      .catch((error: Error) => {
        setState({ error: error.message });
      });
  }, [location]);

  const onPaginate = useCallback(
    async ({ year, nextPage }: { year?: string; nextPage: number }) => {
      setState({
        data: {
          ...state.data!,
          tableData: null,
        },
      });

      navigate(`/pinel/comptabilite${year ? '/' + year : ''}${nextPage ? '/page/' + nextPage : ''}`);
    },
    [state.data]
  );

  const setCashflowToAddEdit = useCallback((newState: PinelCashflowForm) => {
    setState({
      cashflowToAddEdit: newState,
    });
  }, []);

  const addOrEditCallbackHandler = useCallback(
    ({
      chartData,
      dashboardData,
      tableData,
    }: {
      chartData: PinelCashflowChartData;
      dashboardData: PinelCashflowDashboardData;
      tableData: {
        rows: PinelCashflowItem[];
        totalCount: number;
      };
    }) => {
      setState({
        data: {
          ...state.data,
          chartData: chartData,
          dashboardData: dashboardData,
          tableData: tableData,
        },
        cashflowToAddEdit: undefined,
      });
    },
    [state.data]
  );

  const cancelCallbackFunction = useCallback(() => {
    setState({
      cashflowToAddEdit: undefined,
    });
  }, []);

  if (state.error != null) {
    return (
      <Alert className="mb-0" variant="danger">
        {state.error}
      </Alert>
    );
  }

  if (state.data === undefined) {
    return (
      <>
        <Row className="mb-3">
          <Col>
            <Placeholder.Button variant="primary" xs={2} size="lg" />
          </Col>
        </Row>
        <ComptaDashboard.Placeholder />
        <TransactionTable.Placeholder />
      </>
    );
  }

  return (
    <>
      <AddEditCashflowForm
        offset={offset}
        cashflowToAddEdit={state.cashflowToAddEdit}
        callbackFunction={addOrEditCallbackHandler}
        cancelCallbackFunction={cancelCallbackFunction}
      />
      <Row className="mb-3">
        <Col>
          <Button
            onClick={() => {
              setCashflowToAddEdit({
                pinel_cashflow_id: null,
                label: '',
                credit: false,
                date: new Date().toISOString().substring(0, 10),
                amount: 0,
              });
            }}
            variant="primary"
          >
            Ajouter une transaction
          </Button>
        </Col>
      </Row>
      <ComptaDashboard dashboardData={state.data.dashboardData} />
      <TransactionTable
        tableData={state.data.tableData}
        onPaginate={onPaginate}
        setCashflowToAddEdit={setCashflowToAddEdit}
      />
      <Chart chartData={state.data.chartData} />
    </>
  );
}

export default Comptabilite;
