import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { IAccountCNGChartData } from '../../../Types';
import { useReducer } from 'react';
import GraphParCompte from './GraphParCompte';
import GraphGlobalValue from './GraphGlobalValue';
import GraphGlobalPercentage from './GraphGlobalPercentage';
import GraphGlobalYearOverYear from './GraphGlobalYearOverYear';
import GraphGlobalMonthOverMonth from './GraphGlobalMonthOverMonth';

type Props = {
  globalChartData: IAccountCNGChartData;
};

type State = {
  current: GRAPH_TYPE;
};

export enum GRAPH_TYPE {
  PAR_COMPTE = 'PAR_COMPTE',
  GLOBALE_EUROS = 'GLOBALE_EUROS',
  GLOBALE_PLUS_VALUE = 'GLOBALE_PLUS_VALUE',
  MONTH_OVER_MONTH = 'MONTH_OVER_MONTH',
  YEAR_OVER_YEAR = 'YEAR_OVER_YEAR',
}

function GlobalGraph({ globalChartData }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      current: GRAPH_TYPE.PAR_COMPTE,
    }
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant={state.current === GRAPH_TYPE.PAR_COMPTE ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.PAR_COMPTE });
              }}
            >
              Par compte
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.GLOBALE_EUROS ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.GLOBALE_EUROS });
              }}
            >
              Globale (en €)
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.GLOBALE_PLUS_VALUE ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.GLOBALE_PLUS_VALUE });
              }}
            >
              Plus Value (%)
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.MONTH_OVER_MONTH ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.MONTH_OVER_MONTH });
              }}
            >
              Mensuelle (%)
            </Button>
            <Button
              variant={state.current === GRAPH_TYPE.YEAR_OVER_YEAR ? 'primary' : 'secondary'}
              onClick={() => {
                setState({ current: GRAPH_TYPE.YEAR_OVER_YEAR });
              }}
            >
              Annuelle (%)
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {state.current === GRAPH_TYPE.PAR_COMPTE && <GraphParCompte globalChartData={globalChartData} />}
      {state.current === GRAPH_TYPE.GLOBALE_EUROS && <GraphGlobalValue globalChartData={globalChartData} />}
      {state.current === GRAPH_TYPE.GLOBALE_PLUS_VALUE && <GraphGlobalPercentage globalChartData={globalChartData} />}
      {state.current === GRAPH_TYPE.MONTH_OVER_MONTH && <GraphGlobalMonthOverMonth globalChartData={globalChartData} />}
      {state.current === GRAPH_TYPE.YEAR_OVER_YEAR && <GraphGlobalYearOverYear globalChartData={globalChartData} />}
    </>
  );
}

function ComponentPlaceholder() {
  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example">
            <Button disabled variant="secondary">
              Par compte
            </Button>
            <Button disabled variant="secondary">
              Globale (en €)
            </Button>
            <Button disabled variant="secondary">
              Plus Value (%)
            </Button>
            <Button disabled variant="secondary">
              Mensuelle (%)
            </Button>
            <Button disabled variant="secondary">
              Annuelle (%)
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mb-3"></Row>
    </>
  );
}

GlobalGraph.Placehoder = ComponentPlaceholder;

export default GlobalGraph;
