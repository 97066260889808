import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { PinelCashflowChartData } from '../../Types';
import { CURRENCY_EURO_FORMAT, CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT } from '../../../../Types';
import { Col, Row } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Cashflow',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

const amorBarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Amortissement',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Prix',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return CURRENCY_EURO_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return CURRENCY_EURO_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  chartData: PinelCashflowChartData;
};

function Chart({ chartData }: Props) {
  const barChartData = {
    labels: chartData.barChartData.map((item) => item.date_year),
    datasets: [
      {
        label: 'Crédit',
        data: chartData.barChartData.map((item) => item.total_credit),
        backgroundColor: '#10A19D',
      },
      {
        label: 'Débit',
        data: chartData.barChartData.map((item) => item.total_debit),
        backgroundColor: '#F92727',
      },
      {
        label: 'Cashflow',
        data: chartData.barChartData.map((item) => item.total_cashflow),
        backgroundColor: '#344D67',
      },
    ],
  };

  const amorBarChartData = {
    labels: chartData.amortChartData.map((item) => item.year),
    datasets: [
      {
        label: 'Amortissement',
        data: chartData.amortChartData.map((item) => item.amortissement),
        backgroundColor: '#508D4E',
      },
    ],
  };

  const lineChartData = {
    labels: chartData.lineChartData.map((item) => item.date_label),
    datasets: [
      {
        label: 'Prix de vente minimum',
        data: chartData.lineChartData.map((item) => item.prixDeVenteMin),
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Capital Restant dû',
        data: chartData.lineChartData.map((item) => item.capitalRestantDu),
        borderColor: '#742774',
      },
    ],
  };

  return (
    <>
      <Row sm={1} lg={2}>
        <Col>
          <Line options={lineChartOptions} data={lineChartData} />
        </Col>
        <Col>
          <Bar options={amorBarChartOptions} data={amorBarChartData} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Bar options={barChartOptions} data={barChartData} />
        </Col>
      </Row>
    </>
  );
}
export default Chart;
