import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import AppLayout from './AppLayout';
import ComptaLayout from './apps/Comptabilite/Layout';
import InvestLayout from './apps/Investissement/Layout';
import Accounting from './apps/Comptabilite/components';
import Loading from './components/Loading';
import { AuthProvider } from './providers/AuthProvider';
import LoginPage, { loginAction, loginLoader } from './routes/LoginPage';
import ErrorPage from './routes/ErrorPage';
import CapitalGaranti from './apps/Investissement/routes/CapitalGaranti';
import CapitalNonGaranti from './apps/Investissement/routes/CapitalNonGaranti';
import Crypto from './apps/Crypto/routes';
import { useEffect } from 'react';
import HomePage from './routes/HomePage';
import PinelLayout from './apps/Pinel/Layout';
import FicheImpot from './apps/Pinel/routes/FicheImpot';
import Comptabilite from './apps/Pinel/routes/Comptabilite';

export const IS_LOCAL_DEV = process.env.REACT_APP_ENV === 'development';

export async function appLoader() {
  await AuthProvider.initUserLogin();
  return null;
}

export const UpdateTheme = () => {
  console.log('updating theme...');
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.body.setAttribute('data-bs-theme', 'dark');
  } else {
    document.body.removeAttribute('data-bs-theme');
  }
};

const router = createBrowserRouter([
  {
    path: '/logout',
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      await AuthProvider.logOut();
      return redirect('/');
    },
  },
  {
    path: 'login',
    action: loginAction,
    loader: loginLoader,
    element: <LoginPage />,
  },
  {
    id: 'root',
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    loader: !IS_LOCAL_DEV ? appLoader : undefined,
    shouldRevalidate: ({}) => {
      return false;
    },
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'pinel',
        element: <PinelLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/pinel/fiche-impot" />,
          },
          {
            path: 'fiche-impot',
            element: <FicheImpot />,
            children: [
              {
                path: ':year?',
                element: <FicheImpot />,
                children: [
                  {
                    path: 'page/:page',
                    element: <FicheImpot />,
                  },
                ],
              },
            ],
          },
          {
            path: 'comptabilite',
            element: <Comptabilite />,
            children: [
              {
                path: 'page/:page',
                element: <Comptabilite />,
              },
            ],
          },
        ],
      },
      {
        path: 'comptabilite',
        element: <ComptaLayout />,
        children: [
          {
            index: true,
            element: <Accounting />,
          },
          {
            path: ':year?/:month?',
            element: <Accounting />,
            children: [
              {
                path: 'page/:page',
                element: <Accounting />,
              },
            ],
          },
        ],
      },
      {
        path: 'investissement',
        element: <InvestLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/investissement/capital-garanti" />,
          },
          {
            path: 'capital-garanti',
            element: <CapitalGaranti />,
          },
          {
            path: 'capital-non-garanti',
            element: <CapitalNonGaranti />,
          },
        ],
      },
      {
        path: 'crypto',
        element: <Crypto />,
        children: [
          {
            path: 'page/:page',
            element: <Crypto />,
          },
        ],
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    UpdateTheme();
  }, []);
  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}

export default App;
