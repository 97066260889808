import { Col, Pagination, Placeholder, Row, Table } from 'react-bootstrap';
import { PinelCashflowForm, PinelCashflowItem } from '../../../Types';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { PAGE_SIZE } from '..';
import TableBody from './TableBody';

type Props = {
  tableData: {
    rows: PinelCashflowItem[];
    totalCount: number;
  } | null;
  onPaginate: ({ nextPage }: { nextPage: number }) => Promise<void>;
  setCashflowToAddEdit: ({}: PinelCashflowForm) => void;
};

function TransactionTable(props: Props) {
  const { page } = useParams();

  const currentOffset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  const onPaginateNext = useCallback(() => {
    const nextPage = (currentOffset + PAGE_SIZE) / PAGE_SIZE + 1;
    props.onPaginate({ nextPage });
  }, [currentOffset, page]);

  const onPaginatePrev = useCallback(() => {
    const nextPage = (currentOffset - PAGE_SIZE) / PAGE_SIZE + 1;
    props.onPaginate({ nextPage });
  }, [currentOffset, page]);

  if (props.tableData) {
    return (
      <>
        <Row>
          <Col className="d-flex justify-content-center">
            <p className="mb-0">
              {currentOffset + 1} à{' '}
              {Math.min(
                currentOffset +
                  (PAGE_SIZE > (props.tableData.rows.length || 0) ? props.tableData.rows.length || 0 : PAGE_SIZE),
                props.tableData.totalCount
              )}{' '}
              sur {props.tableData.totalCount}
            </p>
          </Col>
          <Col md={6}></Col>
          <Col className="d-flex justify-content-end">
            <Pagination className="mb-0">
              <Pagination.Prev disabled={currentOffset === 0} onClick={() => onPaginatePrev()} />
              <Pagination.Next
                disabled={(props.tableData.totalCount || 0) <= currentOffset + PAGE_SIZE}
                onClick={() => onPaginateNext()}
              />
            </Pagination>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table className="text-center" striped responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Libellé</th>
                  <th>Montant</th>
                  <th style={{ width: '150px' }}></th>
                </tr>
              </thead>
              <TableBody tableData={props.tableData} setCashflowToAddEdit={props.setCashflowToAddEdit} />
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  return <ComponentPlaceholder />;
}

function ComponentPlaceholder() {
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <Placeholder xs={6} />
        </Col>
        <Col md={6}></Col>
        <Col className="d-flex justify-content-end">
          <Placeholder.Button variant="secondary" xs={3} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Table className="text-center" striped responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Libellé</th>
                <th>Montant</th>
                <th style={{ width: '150px' }}></th>
              </tr>
            </thead>
            <TableBody.Placeholder />
          </Table>
        </Col>
      </Row>
    </>
  );
}

TransactionTable.Placeholder = ComponentPlaceholder;

export default TransactionTable;
