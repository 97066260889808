import { Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { IAccountCGGlobalYearOverYearData } from '../../../Types';
import { PERCENTAGE_FORMAT } from '../../../../../Types';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Performance Annuelle',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return PERCENTAGE_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return PERCENTAGE_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  globalYearOverYearData: IAccountCGGlobalYearOverYearData[];
};

function GlobalYearOverYearPercentage({ globalYearOverYearData }: Props) {
  const data = {
    labels: globalYearOverYearData.map((item) => item.year),
    datasets: [
      {
        label: 'Year over year return',
        data: globalYearOverYearData.map(
          (item) => (Number(item.total_interest) - Number(item.total_impot)) / Number(item.total)
        ),
        backgroundColor: 'rgb(25, 135, 84)',
      },
    ],
  };

  return (
    <Row className="mb-3">
      <Bar options={options} data={data} />
    </Row>
  );
}

function ComponentPlaceholder() {
  return <Row className="mb-3"></Row>;
}

GlobalYearOverYearPercentage.Placeholder = ComponentPlaceholder;

export default GlobalYearOverYearPercentage;
