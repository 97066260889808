import { Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IAccountCNGChartData } from '../../../Types';
import { PERCENTAGE_FORMAT, PERCENTAGE_WITHOUT_DECIMAL_FORMAT } from '../../../../../Types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Performance Annuelle',
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return PERCENTAGE_FORMAT.format(context.raw);
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a Euro sign in the ticks
        callback: function (value: any, _: any, __: any) {
          return PERCENTAGE_WITHOUT_DECIMAL_FORMAT.format(value);
        },
      },
    },
  },
};

type Props = {
  globalChartData: IAccountCNGChartData;
};

function GraphGlobalYearOverYear({ globalChartData }: Props) {
  const data = {
    labels: globalChartData.yearOverYearReturn.map((item) => item.year),
    datasets: [
      {
        label: 'Year over year return',
        data: globalChartData.yearOverYearReturn.map((item) => item.value),
        backgroundColor: globalChartData.yearOverYearReturn.map((item) =>
          item.value > 0 ? 'rgb(14, 164, 122)' : 'rgb(224, 20, 76)'
        ),
      },
    ],
  };

  return (
    <Row className="mb-3">
      <Bar options={options} data={data} />
    </Row>
  );
}

export default GraphGlobalYearOverYear;
