import { Col, Placeholder, Row } from 'react-bootstrap';
import { IAccountCNGDashboard } from '../../Types';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../Types';

type Props = {
  dashboard: IAccountCNGDashboard;
};

function GlobalKPI({ dashboard }: Props) {
  return (
    <Row xs={1} sm={2} lg={4} className="mb-3 gy-3 text-center">
      <Col className="text-primary">
        <h3>Situation</h3>
        <h3>{CURRENCY_EURO_FORMAT.format(dashboard.total_current_value)}</h3>
      </Col>
      <Col className="text-info">
        <h3>Investissement</h3>
        <h3>{CURRENCY_EURO_FORMAT.format(dashboard.total_invested_amount)}</h3>
      </Col>
      <Col className="text-success">
        <h3>Perf</h3>
        <h3>
          {CURRENCY_EURO_FORMAT.format(dashboard.total_return)}
          {` `} ({PERCENTAGE_FORMAT.format(dashboard.total_return_percentage)})
        </h3>
      </Col>
      <Col className="text-success">
        <h3>Perf depuis 01/01</h3>
        <h3>{PERCENTAGE_FORMAT.format(dashboard.year_to_date_return_percentage)}</h3>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={4} className="mb-3 gy-3 text-center">
      <Col className="text-primary">
        <h3>Situation</h3>
        <h3>
          <Placeholder xs={8} />
        </h3>
      </Col>
      <Col className="text-info">
        <h3>Investissement</h3>
        <h3>
          <Placeholder xs={8} />
        </h3>
      </Col>
      <Col className="text-success">
        <h3>Perf</h3>
        <h3>
          <Placeholder xs={8} />
        </h3>
      </Col>
      <Col className="text-success">
        <h3>Perf depuis 01/01</h3>
        <h3>
          <Placeholder xs={8} />
        </h3>
      </Col>
    </Row>
  );
}

GlobalKPI.Placehoder = ComponentPlaceholder;

export default GlobalKPI;
