import { IAccountCGTransactionForm, IAccountCGDashboard, IAccountCG, IAccountCGGlobalChartData } from '../../Types';
import { useCallback, useEffect, useReducer } from 'react';
import AddEditTransactionForm from './AddEditTransactionForm';
import AccountCard from './AccountCard';
import ApiProvider from '../../ApiProvider';
import GlobalKPI from './GlobalKPI';
import { Alert } from 'react-bootstrap';
import GlobalGraph from './GlobalGraph';

export type FormState = {
  transactionToAddEdit?: IAccountCGTransactionForm;
  selectedAccount: IAccountCG;
  selectedOffset: number;
  updateAccountCallback: (updatedAccount: IAccountCG) => void;
};

type State = {
  isLoading: boolean;
  data?: {
    accountDataList: IAccountCG[];
    globalDashboardData: IAccountCGDashboard;
    globalChartData: IAccountCGGlobalChartData;
  };
  error: string | null;
  form?: FormState;
};

export const DEFAULT_TABLE_SIZE = 5;

const DEFAULT_TRANSACTION_FORM_VALUE: Partial<IAccountCGTransactionForm> = {
  account_cg_transaction_id: null,
  date: new Date().toISOString().substring(0, 10),
  interest: false,
  amount: 0,
};

function CapitalGaranti() {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isLoading: true,
      error: null,
    }
  );

  useEffect(() => {
    setState({ isLoading: true });

    ApiProvider.getAllAccountCg({ limit: DEFAULT_TABLE_SIZE })
      .then((data) => {
        setState({
          data: {
            accountDataList: data.accountDataList,
            globalDashboardData: data.globalDashboardData,
            globalChartData: data.globalChartData,
          },
        });
      })
      .catch((error: Error) => {
        setState({ error: error.message });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  }, []);

  const onEditHandler = useCallback(
    ({ transactionToAddEdit, selectedAccount, selectedOffset, updateAccountCallback }: FormState) => {
      setState({
        form: {
          transactionToAddEdit,
          selectedAccount,
          selectedOffset,
          updateAccountCallback,
        },
      });
    },
    []
  );

  const onAddHandler = useCallback(({ selectedAccount, selectedOffset, updateAccountCallback }: FormState) => {
    setState({
      form: {
        transactionToAddEdit: {
          ...DEFAULT_TRANSACTION_FORM_VALUE,
          fk_account_cg_id: selectedAccount.account_cg_id,
        } as IAccountCGTransactionForm,
        selectedAccount,
        selectedOffset,
        updateAccountCallback,
      },
    });
  }, []);

  const addOrEditCallbackHandler = useCallback(
    ({
      accountData,
      globalDashboardData,
      globalChartData,
    }: {
      accountData: IAccountCG;
      globalDashboardData: IAccountCGDashboard;
      globalChartData: IAccountCGGlobalChartData;
    }) => {
      state.form?.updateAccountCallback(accountData);
      setState({
        data: {
          ...state.data!,
          globalDashboardData: globalDashboardData,
          globalChartData: globalChartData,
        },
        form: undefined,
      });
    },
    [state.data, state.form]
  );

  const cancelCallbackFunction = useCallback(() => {
    setState({
      form: undefined,
    });
  }, []);

  if (state.isLoading) {
    return (
      <>
        <GlobalKPI.Placeholder />
        <GlobalGraph.Placeholder />
        {Array.from(Array(3).keys()).map((item) => {
          return <AccountCard.Placeholder key={item} />;
        })}
      </>
    );
  }

  if (state.error) {
    return (
      <Alert className="mb-0" variant="danger">
        {state.error}
      </Alert>
    );
  }

  return (
    <>
      <AddEditTransactionForm
        account={state.form?.selectedAccount}
        transactionToAddEdit={state.form?.transactionToAddEdit}
        offset={state.form?.selectedOffset || 0}
        callbackFunction={addOrEditCallbackHandler}
        cancelCallbackFunction={cancelCallbackFunction}
      />
      <GlobalKPI dashboard={state.data!.globalDashboardData} />
      <GlobalGraph globalChartData={state.data!.globalChartData} />
      <div className="d-flex flex-column gap-4">
        {state.data?.accountDataList.map((item) => {
          return (
            <AccountCard
              key={item.account_cg_id}
              account={item}
              onEditHandler={onEditHandler}
              onAddHandler={onAddHandler}
            />
          );
        })}
      </div>
    </>
  );
}
export default CapitalGaranti;
