import { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { PinelFicheImpotData, PinelRefTransactionType, PinelTransactionForm, PinelTransactionItem } from '../../Types';
import ApiProvider from '../../ApiProvider';
import { PAGE_SIZE } from '.';

type Props = {
  transactionToAddEdit?: PinelTransactionForm;
  callbackFunction: ({
    availableYears,
    ficheImpotData,
    tableData,
  }: {
    availableYears: number[];
    ficheImpotData: PinelFicheImpotData[];
    tableData: {
      rows: PinelTransactionItem[];
      totalCount: number;
    };
  }) => void;
  transactionType: PinelRefTransactionType[];
  offset: number;
  year: string | undefined;
  cancelCallbackFunction: () => void;
};

type State = {
  isProcessing: boolean;
  error: string | null;
  success: string | null;
  formData?: PinelTransactionForm;
};

function AddEditTransactionForm({
  transactionToAddEdit,
  callbackFunction,
  transactionType,
  cancelCallbackFunction,
  offset,
  year,
}: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isProcessing: false,
      error: null,
      success: null,
    }
  );

  useEffect(() => {
    if (transactionToAddEdit) {
      setState({ formData: transactionToAddEdit });
    }
  }, [transactionToAddEdit]);

  const onFormInputHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
      let value: number | string | Date | null = event.target.value;

      if (event.target.tagName === 'SELECT') {
        value = event.target.value === '' ? null : Number(event.target.value);
      }

      setState({
        formData: {
          ...state.formData,
          [event.target.name]: value,
        } as PinelTransactionForm,
      });
    },
    [state.formData]
  );

  const handleFormSubmit = useCallback(
    async (transaction?: PinelTransactionForm) => {
      if (transaction) {
        setState({ isProcessing: true });

        ApiProvider.addOrUpdateTransaction({
          transaction,
          limit: PAGE_SIZE,
          offset: offset,
          year: year ? Number(year) : undefined,
        })
          .then((data) => {
            callbackFunction(data.data);
            setState({ success: data.message, error: undefined });
          })
          .catch((err) => {
            setState({ error: err.message });
          })
          .finally(() => {
            setState({ isProcessing: false });
          });
      }
    },
    [callbackFunction, offset, year]
  );

  const onCancelHandler = useCallback(() => {
    setState({
      formData: undefined,
      isProcessing: false,
      error: null,
      success: null,
    });
    cancelCallbackFunction();
  }, [cancelCallbackFunction]);

  return (
    <Modal size="lg" backdrop="static" show={state.formData === undefined ? false : true} onHide={onCancelHandler}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(state.formData);
        }}
      >
        <Modal.Header closeButton={!state.isProcessing}>
          {state.formData && (
            <Modal.Title>
              {state.formData.pinel_impot_transaction_id === null
                ? `Ajouter une transaction`
                : `Modifier une transaction`}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.success && (
            <Alert className="mb-0" variant="success">
              {state.success}
            </Alert>
          )}
          {state.formData && !state.success && (
            <>
              <Form.Group className="mb-3" controlId="transactionTypeInput">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  required
                  aria-label="Choisir le type de la transaction"
                  onChange={onFormInputHandler}
                  name="fk_ref_transaction_type_id"
                  value={
                    state.formData.fk_ref_transaction_type_id === null
                      ? undefined
                      : state.formData.fk_ref_transaction_type_id
                  }
                >
                  <option value={''}>--- Choisir le type de la transaction ---</option>
                  {transactionType.map((item, key) => {
                    return (
                      <option key={key} value={item.id}>
                        {item.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="libelleInput">
                <Form.Label>Libellé</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Loyer 2022"
                  onChange={onFormInputHandler}
                  name="label"
                  value={state.formData.label}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="dateInput">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  onChange={onFormInputHandler}
                  name="date"
                  defaultValue={state.formData.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="montantInput">
                <Form.Label>Montant</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="any"
                  min="1"
                  onChange={onFormInputHandler}
                  name="amount"
                  value={state.formData.amount}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={state.isProcessing}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Fermer
          </Button>
          {!state.success && (
            <Button
              variant="primary"
              type="submit"
              className="d-flex flex-row gap-2 align-items-center"
              disabled={state.isProcessing}
            >
              {state.isProcessing ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span>Enregistrement...</span>
                </>
              ) : (
                'Enregistrer'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditTransactionForm;
