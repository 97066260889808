import { Col, Placeholder, Row } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../Types';

type Props = {
  totalCurrentValue: number;
  totalInvestedAmount: number;
  variationPercentage: number;
  variation: number;
};

const TITLE_DATE_FORMAT = Intl.DateTimeFormat('fr-FR', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

function KPI({ totalCurrentValue, totalInvestedAmount, variation, variationPercentage }: Props) {
  return (
    <Row xs={1} md={4} className="mb-3 gy-3 text-center">
      <Col>
        <h3>Dernière MAJ</h3>
        <h3>{TITLE_DATE_FORMAT.format(new Date())}</h3>
      </Col>
      <Col className="text-primary">
        <h3>Solde</h3>
        <h3>{CURRENCY_EURO_FORMAT.format(totalCurrentValue)}</h3>
      </Col>
      <Col className="text-info">
        <h3>Investissement</h3>
        <h3>{CURRENCY_EURO_FORMAT.format(totalInvestedAmount)}</h3>
      </Col>
      <Col className={`${variation! > 0 ? 'text-success' : 'text-danger'}`}>
        <h3>Variation</h3>
        <h3>
          {CURRENCY_EURO_FORMAT.format(variation)} ({PERCENTAGE_FORMAT.format(variationPercentage)})
        </h3>
      </Col>
    </Row>
  );
}

function ComponentPlacholder() {
  return (
    <Row xs={1} md={4} className="mb-3 gy-3 text-center">
      <Col>
        <h3>Dernière MAJ</h3>
        <h3>
          <Placeholder xs="5" />
        </h3>
      </Col>
      <Col className="text-primary">
        <h3>Solde</h3>
        <h3>
          <Placeholder xs="5" />
        </h3>
      </Col>
      <Col className="text-info">
        <h3>Investissement</h3>
        <h3>
          <Placeholder xs="5" />
        </h3>
      </Col>
      <Col className="text-secondary">
        <h3>Variation</h3>
        <h3>
          <Placeholder xs="5" />
        </h3>
      </Col>
    </Row>
  );
}

KPI.Placeholder = ComponentPlacholder;

export default KPI;
