import { useCallback } from 'react';
import { Col, Pagination, Placeholder, Row, Table as TableComponent } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Table from './Table';
import { CryptoTransactionTableData } from '../../Types';
import TableRow from './TableRow';
import { PAGE_SIZE } from '..';

type Props = {
  tableData: CryptoTransactionTableData | null;
  onPaginate: ({ nextPage }: { nextPage: number }) => Promise<void>;
};

function CryptoTransactionTable({ tableData, onPaginate }: Props) {
  const { page } = useParams();

  const currentOffset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  const onPaginateNext = useCallback(() => {
    const nextPage = (currentOffset + PAGE_SIZE) / PAGE_SIZE + 1;
    onPaginate({ nextPage });
  }, [currentOffset, page]);

  const onPaginatePrev = useCallback(() => {
    const nextPage = (currentOffset - PAGE_SIZE) / PAGE_SIZE + 1;
    onPaginate({ nextPage });
  }, [currentOffset, page]);

  if (tableData === null) {
    return <ComponentPlaceholder />;
  }

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <p className="mb-0">
            {currentOffset + 1} à{' '}
            {Math.min(
              currentOffset + (PAGE_SIZE > (tableData.rows.length || 0) ? tableData.rows.length || 0 : PAGE_SIZE),
              tableData.totalCount
            )}{' '}
            sur {tableData.totalCount}
          </p>
        </Col>
        <Col md={6}></Col>
        <Col className="d-flex justify-content-end">
          <Pagination className="mb-0">
            <Pagination.Prev disabled={currentOffset === 0} onClick={() => onPaginatePrev()} />
            <Pagination.Next
              disabled={(tableData.totalCount || 0) <= currentOffset + PAGE_SIZE}
              onClick={() => onPaginateNext()}
            />
          </Pagination>
        </Col>
      </Row>
      <Table tableData={tableData} />
    </>
  );
}

function ComponentPlaceholder() {
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <Placeholder size="sm" xs={6} />
        </Col>
        <Col md={6}></Col>
        <Col className="d-flex justify-content-end">
          <Placeholder.Button variant="secondary" xs={3} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableComponent className="text-center" striped responsive>
            <thead>
              <tr>
                <th style={{ width: '150px' }}>Date</th>
                <th style={{ width: '150px' }}>Crypto</th>
                <th style={{ width: '150px' }}>Montant</th>
                <th style={{ width: '150px' }}>Prix</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(Array(PAGE_SIZE).keys()).map((_, index) => (
                <TableRow.Placeholder key={index} />
              ))}
            </tbody>
          </TableComponent>
        </Col>
      </Row>
    </>
  );
}

CryptoTransactionTable.Placeholder = ComponentPlaceholder;

export default CryptoTransactionTable;
