import React, { useCallback, useEffect, useReducer } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AccountingItem,
  IAccountingDashboardData,
  IAccountingData,
  IAccountingDate,
  IAccountingTableData,
} from '../Types';
import Filter from './Filter';
import Dashboard from './Dashboard';
import ApiProvider from '../ApiProvider';
import AccountingTable from './AccountingTable';
import { Alert, Button, Col, Placeholder, Row } from 'react-bootstrap';
import ImportForm from './ImportForm';

export const PAGE_SIZE: number = 50;
export const ORDER_BY: string = 'date';
export const SORT: string = 'asc';

type State = {
  data?: {
    dates: IAccountingDate | null;
    tableData: IAccountingTableData | null;
    dashboard: IAccountingDashboardData | null;
  };
  error: string | null;
  displayForm: boolean;
};

function Accounting() {
  const { year, month, page } = useParams();
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    { error: null, displayForm: false }
  );

  const navigate = useNavigate();
  let location = useLocation();
  const offset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  useEffect(() => {
    setState({ error: null });
    ApiProvider.getAccounting({
      year: year,
      month: month,
      limit: PAGE_SIZE,
      offset: offset,
      orderBy: ORDER_BY,
      sort: SORT,
      tableOnly: state.data?.dashboard ? true : false,
    })
      .then((data: IAccountingData) => {
        setState({
          data: {
            ...state.data,
            ...data,
          },
        });
      })
      .catch((error: Error) => {
        setState({ error: error.message });
      });
  }, [location]);

  const onUpdateRow = useCallback(
    async (accountingItem: AccountingItem) => {
      try {
        const offset = page ? (Number(page) - 1) * PAGE_SIZE : 0;
        const data = await ApiProvider.updateAccountingCashflow({
          accounting_id: accountingItem.accounting_id,
          cashflow: !accountingItem.cashflow,
          year: year,
          month: month,
          limit: PAGE_SIZE,
          offset: offset,
          orderBy: ORDER_BY,
          sort: SORT,
        });

        setState({
          data: {
            ...state.data!,
            tableData: data.tableData,
            dashboard: data.dashboard,
          },
        });
      } catch (error: any) {
        setState({ error: error.message });
      }
    },
    [state.data, year, month, page]
  );

  const onFilterUpdate = useCallback(
    async ({ year, month }: { year?: string; month?: string }) => {
      setState({
        data: {
          ...state.data!,
          tableData: null,
          dashboard: null,
        },
      });

      navigate(`/comptabilite${year ? '/' + year : ''}${month ? '/' + month : ''}`);
    },
    [state.data]
  );

  const onPaginate = useCallback(
    async ({ year, month, nextPage }: { year?: string; month?: string; nextPage: number }) => {
      setState({
        data: {
          ...state.data!,
          tableData: null,
        },
      });

      navigate(
        `/comptabilite${year ? '/' + year : ''}${month ? '/' + month : ''}${nextPage ? '/page/' + nextPage : ''}`
      );
    },
    [state.data]
  );

  const closeForm = useCallback(() => {
    setState({ displayForm: false });
  }, []);

  if (state.error != null) {
    return (
      <Alert className="mb-0" variant="danger">
        {state.error}
      </Alert>
    );
  }

  if (state.data === undefined) {
    return (
      <>
        <Row className="mb-3">
          <Col>
            <Placeholder.Button variant="primary" xs={2} size="lg" />
          </Col>
        </Row>
        <Filter.Placeholder />
        <Dashboard.Placeholder />
        <AccountingTable.Placeholder />
      </>
    );
  }

  return (
    <>
      <ImportForm displayForm={state.displayForm} closeForm={closeForm} />
      <Row className="mb-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              setState({ displayForm: true });
            }}
          >
            Importer des données
          </Button>
        </Col>
      </Row>
      {state.data.dates ? <Filter onFilterUpdate={onFilterUpdate} dates={state.data.dates!} /> : <Filter.Placeholder />}
      {state.data.dashboard ? <Dashboard dashboard={state.data.dashboard} /> : <Dashboard.Placeholder />}
      {state.data.tableData ? (
        <AccountingTable onPaginate={onPaginate} onUpdateRow={onUpdateRow} tableData={state.data.tableData} />
      ) : (
        <AccountingTable.Placeholder />
      )}
    </>
  );
}
export default Accounting;
