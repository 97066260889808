import { Col, Nav, Row } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';

function Layout() {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <h1>Pinel</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/pinel/fiche-impot">
                Fiche Impôt
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/pinel/comptabilite">
                Comptabilité
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}
export default Layout;
